const colors = {
    DarkBlue: '#162A35',
    lightBlue: '#0071A13D',
    azulDash: '#0071A1',
    gray: '#F9F9F9',
    ligthGray: '#757575',
    white:'#ffffff',
    textBlue: '#3F4254',
    background: '#EFF0F9',
};

export default colors;