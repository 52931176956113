import styled from 'styled-components';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';


const { DarkBlue, lightBlue } = colors;
const { notoSans700 } = fonts;

export const ButtonContainer = styled.button`
    background-color: ${DarkBlue};
    color: #fff;
    width: 100%;
    height: 51.3px;
    margin-top: 20px;
    margin-bottom: 10px;
    border: none;
    border-radius: 11px;
    transition: background-color 0.2s;
    cursor: pointer;

    ${notoSans700};
    font-size: 18px;
    padding: 10px;
    text-align: center;

    a {
        color: #fff;
        
    }
    
    &:hover{
        background-color: ${lightBlue};
        
    }

`;
