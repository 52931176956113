import styled from "styled-components";

export const ModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.35);
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.8s ease;

  @keyframes fadeIn {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const ModalForeground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
  width: 1200px;
  border-radius: 15px;
  position: relative;
  background-color: #fff;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  color: #306f9d;
  background-color: #fff;
  border: 1px solid #306f9d;
  padding: 8px 25px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 1.1em;
  width: 180px;
  height: 50px;
`;

export const SubmitButton = styled.button`
  cursor: pointer;
  color: #fff;
  background-color: ${(props) => props.backgroundColor || "#306f9d"};
  border: 1px solid #306f9d;
  padding: 8px 45px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 1.1em;
  width: 210px;
  height: 50px;
`;

export const TitleModal = styled.h1`
  color: #000;
  font-size: 2.5em;
  font-weight: 400;
  position: absolute;
  top: 20px;
`;

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 65px;
  width: 100%;
`;

export const ContainerInputs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  width: 100%;
`;

export const ContainerButtons = styled.div`
  position: absolute;
  bottom: 30px;
  right: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const UploadButton = styled.label`
  margin-top: 20px;
  background-color: #eeeeee;
  color: #000;
  font-weight: 400;
  width: 240px;
  border-radius: 10px;
  font-size: 14px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`;
