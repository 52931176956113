import * as yup from "yup";

const schemaLogin = yup.object({
  login: yup
    .string()
    .required("O e-mail é obrigatório !")
    .email("Digite um e-mail válido !"),
  senha: yup.string().required("A senha é obrigatório !"),
});

export default schemaLogin;
