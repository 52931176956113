import React, { useRef, useState, useEffect } from 'react'
// import { EventContext } from '../../../context/EventContext'
// import getValidationErrors from '../../../utils/getValidationErrors'
// import Button from '../../../components/Button'
// import updateEvents from '../../../context/EventContext'
import api from '../../../../../services/api'
import { toast } from 'react-toastify'
// import * as Yup from 'yup'
// import { useField } from '@unform/core'
// import Input from '../../../components/Input'
// import Select from '../../../components/select'

// import { Container, Forms, Body } from './styles'

import '../../../../../styles/global.css'
// import InputSmall from '../../../components/InputMenor'
// import { useHistory } from 'react-router-dom'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  Input,
  Select,
  Text,
  Button,
} from '@chakra-ui/react'
import { Grid, GridItem } from '@chakra-ui/react'

function ModalEdit({
  setShowModalNew,
  categoria,
  isOpenNew,
  setUpdate,
  update,
}) {
  const [name, setName] = useState('')
  const [status, setStatus] = useState(1)

  //const {  updateEvents } = useContext(EventContext);
  const createSubcategoria = async () => {
    try {
      if (!name || name.length < 0) {
        toast.error('Preencha todos os campos para cadastrar a subcategoria')
      } else {
        const response = await api.post(`/subcategorias/`, {
          name,
          categorias_id: categoria?.id,
          status,
        })
        console.log(response)
        toast('Subcategoria criada com sucesso', 'sucess')
        setShowModalNew(false)
        setUpdate(update + 1)
        return response.data
      }
    } catch (err) {
      console.log(err)
      toast.error(
        err?.response?.data?.error ||
          'Ops... ocorreu um erro ao editar a subcategoria.',
      )
      return false
    }
  }
  // const handleSubmit = async (data) => {
  //   try {
  //     formRef.current?.setErrors({})
  //     const schema = Yup.object().shape({
  //       name: Yup.string().required('Nome obrigatório'),
  //       date: Yup.date().required('Data obrigatória'),
  //       // imageEvent: Yup.string().required("Imagem do evento obrigatória"),
  //       status: Yup.number().required(
  //         'Por favor digite 1 para eventos ativos ou 0 para inativos',
  //       ),
  //     })
  //     await schema.validate(data, {
  //       abortEarly: false,
  //     })
  //     //formRef.current?.setErrors({});

  //     const resp = await updateEvents(data, event?.id)
  //     console.log(resp)
  //     loading()
  //     setShowModalEdit(false)
  //   } catch (err) {
  //     console.log(err)
  //     const errors = getValidationErrors(err)
  //     formRef.current?.setErrors(errors)
  //     console.log(errors)
  //   }
  // }
  return (
    <Modal size="4xl" isOpen={isOpenNew} onClose={setShowModalNew} isCentered> 
      <ModalOverlay />
      <ModalContent textAlign="center">
        <ModalHeader>Cadastro de Subcategoria</ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(2, 2fr)" gap={6}>
            <GridItem textAlign="start" w="100%">
              <Text mb="0.5rem">Nome da Subcategoria:</Text>
              <Input
                name="name"
                placeholder="Nome da Subcategoria"
                onChange={(e) => setName(e.target.value)}
              />
            </GridItem>
            <GridItem w="100%">
              <Text mb="0.5rem">Status da Subcategoria:</Text>
              <Select onChange={(e) => setStatus(e.target.value)}>
                <option value="1">Ativo</option>
                <option value="0">Inativo</option>
              </Select>
            </GridItem>
          </Grid>
          <Center mt="3rem" mb="3rem">
            <Button
              bg="#162A35"
              color="white"
              w="40%"
              mr="1rem"
              size="lg"
              onClick={createSubcategoria}
            >
              Confirmar
            </Button>
            <Button w="40%" size="lg" onClick={() => setShowModalNew(false)}>
              Fechar
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalEdit
