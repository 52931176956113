import styled from "styled-components";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import { Form } from "@unform/web";
import { MdEmail } from "react-icons/md";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

const { Mulish700 } = fonts;
const { lightBlue } = colors;

export const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eff1f9;

  a {
    ${Mulish700};
    text-decoration: none;
    color: ${colors.white};

    &:hover {
      color: ${lightBlue};
      text-decoration: underline;
    }
  }
`;

export const Forms = styled(Form)`
  display: flex;
  width: 70%;
  flex-direction: column;
  margin-top: 50px;
  align-self: center;

  span {
    margin-top: 20px;
    font-size: 12px;
    color: #f1f2f1;
  }
`;

export const ContainerInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconEmail = styled(MdEmail)`
  color: #162a35;
  position: absolute;
  right: 20px;
  font-size: 1.2em;
`;

export const IconPasswordInvisible = styled(AiFillEyeInvisible)`
  color: #162a35;
  position: absolute;
  right: 20px;
  font-size: 1.4em;
  cursor: pointer;
`;

export const IconPasswordVisible = styled(AiFillEye)`
  color: #162a35;
  position: absolute;
  right: 20px;
  font-size: 1.4em;
  cursor: pointer;
`;

export const LabelError = styled.span`
  color: #f10000;
  font-weight: 700;
  font-size: 0.9em;
  bottom: -28px;
  position: absolute;
  animation: fadeIn 0.4s ease-in-out;

  @keyframes fadeIn {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const InputLogin = styled.input`
  background-color: ${colors.gray};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 15px;
  width: ${(props) => props.width || `350px`};
  color: ${colors.ligthGray};

  ::placeholder {
    color: ${colors.ligthGray};
  }

  :focus {
    border: none;
  }
`;
