import React, { useContext, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "../../components/Card";
import {
  Container,
  ContainerInput,
  IconEmail,
  IconPasswordInvisible,
  IconPasswordVisible,
  InputLogin,
  LabelError,
} from "./styles";
import { Image } from "../../components/Images/styles";
import logo from "../../assets/images/logo.svg";

import Button from "../../components/Button";

import { AuthContext } from "../../context/UserContext";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import schemaLogin from "./schema";

function SingIn() {
  const { singIn } = useContext(AuthContext);
  const [typeInput, setTypeInput] = useState("password");
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    shouldFocusError: true,
    defaultValues: {
      login: "",
      senha: "",
    },
    resolver: yupResolver(schemaLogin),
  });

  const handleAutheticationUser = async (data) => {
    try {
      const response = await singIn(data);
      return response?.data;
    } catch (err) {
      console.log(err);
      toast.success(err?.response?.data?.message);
    }
  };

  return (
    <Container>
      <Card>
        <Image src={logo} />
        <form
          onSubmit={handleSubmit(handleAutheticationUser)}
          style={{
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "35px",
          }}
        >
          <ContainerInput>
            <InputLogin
              type="text"
              placeholder="E-mail"
              {...register("login")}
              width={"320px"}
            />
            <IconEmail />
            {errors.login && errors?.login?.message ? (
              <LabelError>{errors?.login?.message}</LabelError>
            ) : (
              false
            )}
          </ContainerInput>
          <ContainerInput>
            <InputLogin
              type={`${typeInput}`}
              width={"320px"}
              placeholder="Senha"
              {...register("senha")}
            />
            {typeInput === "password" ? (
              <IconPasswordInvisible onClick={() => setTypeInput("text")} />
            ) : (
              <IconPasswordVisible onClick={() => setTypeInput("password")} />
            )}
            {errors.senha && errors?.senha?.message ? (
              <LabelError>{errors?.senha?.message}</LabelError>
            ) : (
              false
            )}
          </ContainerInput>
          {isSubmitting ? (
            <Button type="submit" color="#fff">
              Entrando...
            </Button>
          ) : (
            <Button type="submit" color="#fff">
              Entrar
            </Button>
          )}
        </form>
      </Card>
    </Container>
  );
}
export default SingIn;
