import {
  Box,
  Text,
  FormLabel,
  Input,
  InputGroup,
  keyframes,
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { motion } from "framer-motion";

const animationKeyFrames = keyframes`
   0% { transform: scale(0)  }
  100% { transform: scale(1) }
`;

const animation = `${animationKeyFrames} .45s ease-in-out normal`;

const InputAnuncio = forwardRef(
  ({ width, label, error, placeholder, left, right, ...rest }, ref) => {
    return (
      <>
        <InputGroup
          border={"1px solid #D0D0D0"}
          width={width}
          position={"relative"}
          borderRadius={"10px"}
          boxShadow="0 4px 4px rgba(0,0,0,0.10)"
        >
          <FormLabel
            color={"#000"}
            position={"absolute"}
            top="-25px"
            fontSize={"15px"}
            fontWeight={400}
          >
            {label}
          </FormLabel>
          <Input
            ref={ref}
            borderRadius={"10px"}
            width={"100%"}
            color={"#000"}
            placeholder={placeholder}
            h={"57px"}
            _placeholder={{
              color: "rgba(0,0,0,0.35)",
            }}
            {...rest}
          />

          {error && (
            <Box
              as={motion.div}
              animation={animation}
              top="-55px"
              left={left}
              right={right}
              padding="5px 10px"
              height={"30px"}
              borderRadius={"10px"}
              position={"absolute"}
              bgColor={"#f10000"}
              fontSize={"0.75em"}
              fontWeight={"bold"}
              bottom={"5px"}
              color={"#fff"}
              textAlign="center"
              zIndex={"1000"}
            >
              <Text>{error}</Text>
              <IoMdArrowDropdown
                style={{
                  color: "#f10000",
                  fontSize: "3em",
                  position: "absolute",
                  bottom: "-20px",
                  right: "10px",
                }}
              />
            </Box>
          )}
        </InputGroup>
      </>
    );
  }
);

export default InputAnuncio;
