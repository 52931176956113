import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { routes } from "../../routes";
import SideBar from "./SideBar";

import { Container, Content } from "./styles";

function Dashboard({ props }) {
  const [title, setTitle] = useState("");
  const { location } = props;

  useEffect(() => {
    routes?.protected?.map((route) => {
      if (route?.path == location?.pathname) {
        setTitle(route.title);
      }
    });
  }, [title, location]);

  return (
    <Container>
      <SideBar ativado={title} />
      <Content>
        {
          <Switch>
            {routes.protected.map(
              (route, index) =>
                route?.role?.find((elemento) => elemento == 1) && (
                  <Route
                    key={index.toString()}
                    path={route.path}
                    exact
                    component={route.component}
                  />
                )
            )}
          </Switch>
        }
      </Content>
    </Container>
  );
}

export default Dashboard;
