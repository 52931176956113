import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../../services/api";
import format from "date-fns/format";

import { RiDeleteBin6Line } from "react-icons/ri";

// SERVICES
import { getNivel } from "../../../../services/auth";
import { parseISO } from "date-fns";
import axios from "axios";

// STYLES
import {
  Flex,
  Stack,
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
  Heading,
  Input,
} from "@chakra-ui/react";
import { toast } from "react-toastify";

// COMPONENTS
import HeaderAccountInfo from "../../../../components/Header";
import CardDescription from "../../../../components/CardDescription";
import ModalCadastroAnuncios from "../../../../components/ModalCadastroAnuncios";
import Pagination from "../../../../components/Pagination";
import ModalEditAnuncios from "../../../../components/ModalEditAnuncios";

const ListAnuncios = () => {
  const level = getNivel();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [adverts, setAdverts] = useState([]);
  const [query, setQuery] = useState("");

  const token = process.env.REACT_APP_TOKEN_ACCESS;

  useEffect(() => {
    if (level === 2) {
      history.push("/dashboard");
    }
  }, []);

  const handleGetAllAdverts = async () => {
    try {
      const response = await api.get("/advert", {
        params: {
          page: page,
          query: query,
        },
      });
      setAdverts(response?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteVideoOfVimeo = async (id) => {
    try {
      const response = await axios.delete(
        `https://api.vimeo.com/videos/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteAdvertById = async (id) => {
    try {
      const response = await api.delete(`/advert/${id}`);
      toast.success(response?.data?.message);
      handleGetAllAdverts();
      return response?.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetAllAdverts();
  }, [query]);

  return (
    <Flex
      width="100%"
      flexDir={"column"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <HeaderAccountInfo />
      <CardDescription
        description={"Total de Anúncios cadastrados"}
        infoCount={adverts?.count}
      />

      <Stack
        bg="white"
        margin="3rem 1rem 1rem 18em"
        borderRadius="6.32px"
        padding="2rem"
        flexDirection="column"
        width={"77%"}
      >
        <Flex alignItems="center" justifyContent="center" gap="20px">
          <Heading color="#000" fontSize="1.2em">
            Lista de anúncios
          </Heading>
          <Input
            width="600px"
            color="#000"
            placeholder="Filtragem de anúncios..."
            bgColor="#eef2f6"
            _placeholder={{
              color: "#515a6b",
            }}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          <ModalCadastroAnuncios refreshAdverts={handleGetAllAdverts} />
        </Flex>

        <Table>
          <Thead>
            <Tr textTransform={"none"}>
              <Td
                fontSize={"16px"}
                fontWeight={700}
                color="#000"
                textAlign={"center"}
              >
                Anunciante
              </Td>
              <Td
                fontSize={"16px"}
                fontWeight={700}
                color="#000"
                textAlign={"center"}
              >
                CPF/CNPJ
              </Td>
              <Td
                fontSize={"16px"}
                fontWeight={700}
                color="#000"
                textAlign={"center"}
              >
                Valor pago
              </Td>
              <Td
                fontSize={"16px"}
                fontWeight={700}
                color="#000"
                textAlign={"center"}
              >
                Validade
              </Td>
              <Td
                fontSize={"16px"}
                fontWeight={700}
                color="#000"
                textAlign={"center"}
              >
                Status
              </Td>
              <Td
                fontSize={"16px"}
                fontWeight={700}
                color="#000"
                textAlign={"center"}
              >
                Ações
              </Td>
            </Tr>
          </Thead>

          <Tbody border={"none"}>
            {adverts.rows?.map((advert) => {
              return (
                <Tr key={advert?.id}>
                  <Td color={"#000"} textAlign={"center"}>
                    {advert?.anunciante}
                  </Td>
                  <Td color={"#000"} textAlign={"center"}>
                    {advert?.identificacao}
                  </Td>

                  <Td color={"#000"} textAlign={"center"}>
                    {parseFloat(advert.valorPago).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Td>

                  <Td color={"#000"} textAlign={"center"}>{`${format(
                    new Date(parseISO(advert?.validadeInicial)),
                    "dd/MM/yyyy"
                  )} - ${format(
                    new Date(parseISO(advert?.validadeFinal)),
                    "dd/MM/yyyy"
                  )}`}</Td>

                  <Td
                    color={advert?.status === "Ativo" ? "#4BB543" : "#f10000"}
                    textAlign={"center"}
                    fontWeight={"bold"}
                  >
                    {advert?.status}
                  </Td>

                  <Td
                    color={"#000"}
                    textAlign={"center"}
                    position={"relative"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ModalEditAnuncios
                      id={advert?.id}
                      refreshAdverts={handleGetAllAdverts}
                    />
                    <RiDeleteBin6Line
                      onClick={() => {
                        handleDeleteAdvertById(advert?.id);
                        handleDeleteVideoOfVimeo(advert?.videoId);
                      }}
                      color="#306F9D"
                      style={{
                        padding: "5px",
                        fontSize: "2em",
                        cursor: "pointer",
                        marginLeft: "8px",
                      }}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Pagination page={page} setPage={setPage} count={adverts?.count} />
      </Stack>
    </Flex>
  );
};

export default ListAnuncios;
