// Formatação de CPF: 123.456.789-10
export const formatCPF = (value) => {
  const cpfRegex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
  return value.replace(cpfRegex, "$1.$2.$3-$4");
};

// Formatação de CNPJ: 12.345.678/0001-90
export const formatCNPJ = (value) => {
  const cnpjRegex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
  return value.replace(cnpjRegex, "$1.$2.$3/$4-$5");
};
