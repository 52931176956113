import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { routes, PrivateRoute } from "./routes";
import Dashboard from "./layouts/Dashboard";

import { AuthProvider } from "./context/UserContext";

const App = () => {
  return (
    <>
      <AuthProvider>
        <Router>
          <Switch>
            {routes?.public?.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact
                component={route.component}
              />
            ))}
            <PrivateRoute path="/dashboard" component={Dashboard} />
          </Switch>
        </Router>
      </AuthProvider>
    </>
  );
};

export default App;
