import api from "../../services/api";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { useEffect } from "react";
import { useState } from "react";

const GraphicWithoutEvents = () => {
  var randomColor = require("randomcolor");
  const [data, setData] = useState([]);

  const getCategories = async () => {
    try {
      const response = await api.get("/countCategoriesWithoutEvents");
      return setData(response?.data?.totalForaEventos);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => getCategories(), []);

  const RADIAN2 = Math.PI / 180;

  const renderCustomizedLabel2 = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN2);
    const y = cy + radius * Math.sin(-midAngle * RADIAN2);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        fontWeight="bold"
        width={100}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {(percent * 100).toFixed(0) !== "0" && `${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <>
      <PieChart width={700} height={700}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel2}
          outerRadius={320}
          dataKey="value"
          nameKey="name"
        >
          {data.map((values) => (
            <Cell
              key={`cell-${values}`}
              fill={randomColor({
                luminosity: "dark",
              })}
            />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </>
  );
};

export default GraphicWithoutEvents;
