import React, { useRef, useContext, useEffect, useState } from 'react'

import {
  Container,
  Body,
  Content,
  ContainerTable,
  SecundaryButton,
} from './styles'
import iconeEdicao from '../../../../assets/images/iconeEdicao.svg'
import iconeExcluir from '../../../../assets/images/iconeExcluir.svg'
import '../../../../styles/global.css'
import api from '../../../../services/api'

import ModalDelete from './ModalDelete'
import ModalEdit from './ModalEdit'
import ModalNew from './ModalNew'
import TableHeader from '../../../../components/TableHeader'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Button,
  Center,
} from '@chakra-ui/react'
import { Table, Thead, Tbody, Tr, Td } from '@chakra-ui/react'
import Pagination from '../../../../components/Pagination'

function ModalSubCategories({
  setShowModalSubcategories,
  categoria,
  isOpenSubs,
}) {
  console.log(categoria)

  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [showModalNew, setShowModalNew] = useState(false)

  const [subcategorySelected, setSubcategorySelected] = useState(null)
  const [subcategories, setSubcategories] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [update, setUpdate] = useState(0)

  useEffect(async () => {
    const response = await api.get(
      `/subcategorias/${categoria?.id}?query=${searchQuery}&page=${page}`,
    )
    console.log(response.data)

    setSubcategories(response.data.rows)
    setCount(response.data.count)
  }, [showModalDelete, showModalEdit, searchQuery, categoria, update, page, isOpenSubs])

  useEffect(async () => {
    setSearchQuery('')
  }, [isOpenSubs])
  return (
    <>
      <ModalEdit
        isOpenEdit={showModalEdit}
        setShowModalEdit={setShowModalEdit}
        subcategoria={subcategorySelected}
        update={update}
        setUpdate={setUpdate}
      />
      <ModalNew
        isOpenNew={showModalNew}
        setShowModalNew={setShowModalNew}
        categoria={categoria}
        update={update}
        setUpdate={setUpdate}
      />
      {showModalDelete && (
        <ModalDelete
          setShowModalDelete={setShowModalDelete}
          subcategoria={subcategorySelected}
          update={update}
          setUpdate={setUpdate}
        />
      )}
      <Modal size="4xl" isOpen={isOpenSubs} onClose={setShowModalSubcategories}>
        <ModalOverlay />
        <ModalContent textAlign="center" padding="2rem">
          <TableHeader
            Title={'Cadastro de Subcategorias'}
            Subtitle={'Listagem de cadastros'}
            setSearchQuery={setSearchQuery}
            ButtonOnClick={() => {setShowModalNew(true)}}
          />
          <Table>
            <Thead>
              <Tr>
                <Td w="20%" textAlign="center"></Td>
                <Td>Nome</Td>
                <Td textAlign="center">Status</Td>
              </Tr>
            </Thead>
            <Tbody>
              {subcategories?.map((subcategory, index) => (
                <Tr key={index}>
                  <Td w="20%">
                    <Button
                      bg="transparent"
                      onClick={() => {
                        setShowModalEdit(true)
                        setSubcategorySelected(subcategory)
                      }}
                    >
                      <img src={iconeEdicao} />
                    </Button>

                    <Button
                      bg="transparent"
                      onClick={() => {
                        setShowModalDelete(true)
                        setSubcategorySelected(subcategory)
                      }}
                    >
                      <img src={iconeExcluir} />
                    </Button>
                  </Td>
                  <Td>{subcategory.name}</Td>
                  <Td textAlign="center">
                    {subcategory.status == 1 ? 'ATIVO' : 'INATIVO'}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            page={page}
            setPage={setPage}
            perPage={5}
            count={count}
          ></Pagination>

          <Center>
            <Button
              mt="2rem"
              size="lg"
              w="40%"
              onClick={() => setShowModalSubcategories(false)}
            >
              Fechar
            </Button>
          </Center>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalSubCategories
