import React, { useRef, useContext, useEffect, useState } from "react";
// import { Container, Body,Forms, Button, SecundaryButton} from "./styles";
import api from "../../../services/api";
import { toast } from "react-toastify";
import "../../../styles/global.css";
// import Input from "../../../components/Input";
// import InputSmall from '../../../components/InputMenor'
// import getValidationErrors from '../../../utils/getValidationErrors'
// import { SelectContainer } from '../../../components/select/styles'
// import Select from "../../../components/select";
import { useHistory } from "react-router-dom";

import { Center, Input, Select, Text, Button, Flex } from "@chakra-ui/react";
import { Grid, GridItem } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";

function Cadastrarevento({ loading, event }) {
  let history = useHistory();

  const inputFile = useRef(null);

  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [imageEvent, setImageEvent] = useState("");
  const [status, setStatus] = useState(1);

  const handleSubmit = async () => {
    try {
      if ((!name && name.length == 0) || (!date && date.length == 0)) {
        toast.error("Preencha todos os campos para cadastrar o evento");
      } else {
        const data = new FormData();
        data.append("name", name);
        data.append("date", date);
        data.append("imageEvent", imageEvent);
        data.append("status", status);
        const response = await api.post(`/events`, data);
        if (response.status === 201) {
          toast("Evento criado com sucesso", "sucess");
          history.push("/dashboard/cadastroeventos");
          setName("");
          setDate("");
          setImageEvent("");
          setStatus("");
        }
      }
    } catch (err) {
      console.log(err);
      alert(err.response.data);
    }
  };

  return (
    <Flex
      bg="white"
      margin="3rem 1rem 1rem 18em"
      borderRadius="6.32px"
      padding="2rem"
      flexDirection="column"
      width={"77%"}
    >
      <Text fontSize="4xl" align="center" color="#000">
        Cadastro de Evento
      </Text>
      <Grid mt="2rem" templateColumns="repeat(2, 2fr)" gap={6}>
        <GridItem textAlign="start" w="100%">
          <Text mb="0.5rem" color="#000">
            Nome do Evento:
          </Text>
          <Input
            sx={{
              border: "1px solid #e2e8f0",
            }}
            defaultValue={event?.name}
            name="name"
            placeholder="Nome do evento"
            _placeholder={{
              color: "#e2e8f0",
            }}
            onChange={(e) => setName(e.target.value)}
          />
        </GridItem>
        <GridItem w="100%">
          <Text mb="0.5rem" color="#000">
            Data do Evento:
          </Text>
          <Input
            border="1px solid #e2e8f0"
            defaultValue={event?.date}
            type="date"
            name="date"
            placeholder="Data do evento"
            onChange={(e) => setDate(e.target.value)}
          />
        </GridItem>
        <GridItem w="100%">
          <Text mb="0.5rem" color="#000">
            Imagem do Evento:
          </Text>
          <Flex>
            <Input
              border="1px solid #e2e8f0"
              type="file"
              name="imageEvent"
              placeholder="Imagem do evento"
              ref={inputFile}
              mr="1rem"
              onChange={(e) => setImageEvent(e.target.files[0])}
            />
            <Button
              onClick={() => inputFile.current.click()}
              bg="#162A35"
              w="1rem"
              borderRadius="50%"
            >
              <EditIcon color="white" />
            </Button>
          </Flex>
        </GridItem>
        <GridItem w="100%">
          <Text mb="0.5rem" color="#000">
            Status do Evento:
          </Text>
          <Select
            style={{
              border: "1px solid #e2e8f0",
              color: "#000",
            }}
            onChange={(e) => setStatus(e.target.value)}
            defaultValue={event?.status}
          >
            <option
              value="1"
              style={{
                backgroundColor: "#fff",
              }}
            >
              Ativo
            </option>
            <option
              value="0"
              style={{
                backgroundColor: "#fff",
              }}
            >
              Inativo
            </option>
          </Select>
        </GridItem>
      </Grid>
      <Center mt="3rem" mb="3rem">
        <Button
          bg="#162A35"
          color="white"
          w="40%"
          mr="1rem"
          size="lg"
          onClick={handleSubmit}
        >
          Cadastrar
        </Button>
        <Button
          w="40%"
          size="lg"
          onClick={() => history.push("/dashboard/cadastroeventos")}
        >
          Fechar
        </Button>
      </Center>
    </Flex>
  );
}

export default Cadastrarevento;
