import styled from "styled-components";
import colors from "../../styles/colors";

const { background } = colors;

export const Container = styled.body`
  display: flex;
  background: ${background};
  overflow-x: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;
