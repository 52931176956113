import React from "react";
import { Flex, Text } from "@chakra-ui/react";

const HeaderAccountInfo = () => {
  return (
    <Flex
      marginTop={"20px"}
      alignItems={"center"}
      justifyContent={"space-around"}
      width={"80%"}
      gap={"15px"}
      marginLeft={"100px"}
    >
      <Text fontSize={"1.7em"} color="#000">
        Dashboard Márcio
      </Text>
      <Text fontSize={"1.7em"} color="#000">
        Seja muito bem vindo, Márcio
      </Text>
    </Flex>
  );
};

export default HeaderAccountInfo;
