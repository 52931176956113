import React from 'react'

import { Modal, ModalOverlay, ModalContent, Image } from '@chakra-ui/react'
export default function ModalImage({ setShowModalImage, event, isOpenImage }) {
  return (
    <Modal size="2xl" isOpen={isOpenImage} onClose={setShowModalImage}>
      <ModalOverlay />
      <ModalContent textAlign="center">
        <Image src={event?.imageEvent} />
      </ModalContent>
    </Modal>
  )
}
