import React, { useRef, useState, useEffect } from 'react'
// import { EventContext } from '../../../context/EventContext'
// import getValidationErrors from '../../../utils/getValidationErrors'
// import Button from '../../../components/Button'
// import updateEvents from '../../../context/EventContext'
import api from '../../../services/api'
import { toast } from 'react-toastify'
// import * as Yup from 'yup'
// import { useField } from '@unform/core'
// import Input from '../../../components/Input'
// import Select from '../../../components/select'

// import { Container, Forms, Body } from './styles'

import '../../../styles/global.css'
// import InputSmall from '../../../components/InputMenor'
// import { useHistory } from 'react-router-dom'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  Input,
  Select,
  Text,
  Button,
  Flex,
  Image,
} from '@chakra-ui/react'
import { Grid, GridItem } from '@chakra-ui/react'
import { EditIcon } from '@chakra-ui/icons'

function ModalEdit({ setShowModalEdit, event, isOpenEdit, setUpdate, update }) {
  const inputFile = useRef(null)
  const [imageEvent, setImageEvent] = useState(null)
  const [name, setName] = useState(event?.name)
  const [status, setStatus] = useState(event?.status)
  const [date, setDate] = useState(event?.date)

  //const {  updateEvents } = useContext(EventContext);
  const updateEvents = async () => {
    try {
      const formData = new FormData()
      formData.append('date', date ? date : event?.date)
      formData.append('imageEvent', imageEvent)
      formData.append('name', name ? name : event?.name)
      formData.append('status', status ? status : event?.status)

      const response = await api.put(`/events/${event?.id}`, formData)
      console.log(response)
      toast('Evento editado com sucesso', 'sucess')
      setShowModalEdit(false)
      setUpdate(update + 1)
      return response.data
    } catch (err) {
      console.log(err)
      toast.error(
        err?.response?.data?.error ||
          'Ops... ocorreu um erro ao editar o evento.',
      )
      return false
    }
  }
  // const handleSubmit = async (data) => {
  //   try {
  //     formRef.current?.setErrors({})
  //     const schema = Yup.object().shape({
  //       name: Yup.string().required('Nome obrigatório'),
  //       date: Yup.date().required('Data obrigatória'),
  //       // imageEvent: Yup.string().required("Imagem do evento obrigatória"),
  //       status: Yup.number().required(
  //         'Por favor digite 1 para eventos ativos ou 0 para inativos',
  //       ),
  //     })
  //     await schema.validate(data, {
  //       abortEarly: false,
  //     })
  //     //formRef.current?.setErrors({});

  //     const resp = await updateEvents(data, event?.id)
  //     console.log(resp)
  //     loading()
  //     setShowModalEdit(false)
  //   } catch (err) {
  //     console.log(err)
  //     const errors = getValidationErrors(err)
  //     formRef.current?.setErrors(errors)
  //     console.log(errors)
  //   }
  // }
  console.log(event)
  return (
    <Modal size="4xl" isOpen={isOpenEdit} onClose={setShowModalEdit}>
      <ModalOverlay />
      <ModalContent textAlign="center">
        <ModalHeader>Edição de Evento</ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(2, 2fr)" gap={6}>
            <GridItem textAlign="start" w="100%">
              <Text mb="0.5rem">Nome do Evento:</Text>
              <Input
                defaultValue={event?.name}
                name="name"
                placeholder="Nome do evento"
                onChange={(e) => setName(e.target.value)}
              />
            </GridItem>
            <GridItem w="100%">
              <Text mb="0.5rem">Nome do Evento:</Text>
              <Input
                defaultValue={event?.date}
                type="date"
                name="date"
                placeholder="Data do evento"
                onChange={(e) => setDate(e.target.value)}
              />
            </GridItem>
            <GridItem w="100%">
              <Text mb="0.5rem">Imagem do Evento:</Text>
              <Flex>
                <Input
                  type="file"
                  name="imageEvent"
                  placeholder="Imagem do evento"
                  ref={inputFile}
                  mr="1rem"
                  onChange={(e) => setImageEvent(e.target.files[0])}
                />
                <Button onClick={() => inputFile.current.click()} bg="#162A35" w="1rem" borderRadius="50%">
                  <EditIcon color="white" />
                </Button>
              </Flex>
            </GridItem>
            <GridItem w="100%">
              <Text mb="0.5rem">Status do Evento:</Text>
              <Select
                onChange={(e) => setStatus(e.target.value)}
                defaultValue={event?.status}
              >
                <option value="1">Ativo</option>
                <option value="0">Inativo</option>
              </Select>
            </GridItem>
          </Grid>
          <Center w="100%">
            <Image
              mt="2rem"
              src={event?.imageEvent}
              alt="imagemEvento"
              objectFit="cover"
            />
          </Center>
          <Center mt="3rem" mb="3rem">
            <Button
              bg="#162A35"
              color="white"
              w="40%"
              mr="1rem"
              size="lg"
              onClick={updateEvents}
            >
              Confirmar
            </Button>
            <Button w="40%" size="lg" onClick={() => setShowModalEdit(false)}>
              Fechar
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
    //   <Container>
    //     <Body>
    //       <h1>Editar Evento</h1>
    //       <img width="100%" src={event.imageEvent} />

    //       <Forms ref={formRef} onSubmit={handleSubmit} initialData={{ ...event }}>
    //         <div>
    //           <span>Nome do Evento</span>
    //           <Input
    //             defaultValue={event.name}
    //             name="name"
    //             placeholder="Nome do evento"
    //           />
    //         </div>
    //         <div>
    //           <span>Data</span>
    //           <Input
    //             defaultValue={event.date}
    //             name="date"
    //             placeholder="Data do evento"
    //           />
    //         </div>
    //         <div className="conjunto-img-add3">
    //           <span>Imagem do evento</span>
    //           <InputSmall
    //             type="file"
    //             name="imageNew"
    //             onChange={(e) => setImageEvent(e.target.files[0])}
    //             placeholder="Imagem do evento"
    //           />
    //           <input class="add-img" type="button"></input>
    //         </div>
    //         <div>
    //           <span>Status</span>
    //           <Select
    //             defaultValue={event.status}
    //             name="status"
    //             placeholder="Status do Evento"
    //           />
    //         </div>

    //         <div className="botao-edit3">
    //           <button type="submit">CONFIRMAR</button>

    //           <a href="#" onClick={() => setShowModalEdit(false)}>
    //             Voltar
    //           </a>
    //         </div>
    //       </Forms>
    //     </Body>
    //   </Container>
  )
}

export default ModalEdit
