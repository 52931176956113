import React, { useState, useEffect } from "react";
import iconeEdicao from "../../../assets/images/iconeEdicao.svg";
import iconeExcluir from "../../../assets/images/iconeExcluir.svg";
import ModalDelete from "./ModalDelete";
import ModalEdit from "./ModalEdit";
import ModalSubCategories from "./ModalSubCategories";
import api from "../../../services/api";
import { useHistory } from "react-router-dom";

import "../../../styles/global.css";
import { Table, Thead, Tbody, Tr, Td } from "@chakra-ui/react";
import {
  Flex,
  Center,
  Text,
  Box,
  Button,
  Spacer,
  Image,
} from "@chakra-ui/react";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";
import HeaderAccountInfo from "../../../components/Header";
import CardDescription from "../../../components/CardDescription";

function ListagemCategorias() {
  let history = useHistory();
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalSubcategories, setShowModalSubcategories] = useState(false);

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [update, setUpdate] = useState(0);
  const [categorias, setCategoria] = useState([]);
  const [categoriaSelected, setCategoriaSelected] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [countCategorias, setCountCategorias] = useState("");
  const [countSubCategorias, setCountSubCategorias] = useState("");

  const loadingCategorias = async () => {
    try {
      const { data: categories } = await api.get(
        `/categorias?query=${searchQuery}&page=${page}`
      );
      setCategoria(categories.rows);
      setCount(categories.count);
    } catch (err) {
      console.log(err);
    }
  };
  const countEvents = async () => {
    try {
      const resp = await api.get(`/countCategorias`);
      setCountCategorias(resp.data);
    } catch (err) {
      console.log(err);
    }
  };
  const subCountCat = async () => {
    try {
      const resp = await api.get(`/countSub`);
      setCountSubCategorias(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadingCategorias();
    countEvents();
    subCountCat();
  }, []);
  useEffect(() => {
    loadingCategorias();
  }, [searchQuery, page, update, showModalSubcategories]);

  return (
    <>
      <ModalSubCategories
        setShowModalSubcategories={setShowModalSubcategories}
        categoria={categoriaSelected}
        isOpenSubs={showModalSubcategories}
        setCategoriaSelected={setCategoriaSelected}
      />
      {showModalDelete && (
        <ModalDelete
          setShowModalDelete={setShowModalDelete}
          categoria={categoriaSelected}
          loading={loadingCategorias}
          update={update}
          setUpdate={setUpdate}
        />
      )}
      <ModalEdit
        isOpenEdit={showModalEdit}
        setShowModalEdit={setShowModalEdit}
        categoria={categoriaSelected}
        loading={loadingCategorias}
        update={update}
        setUpdate={setUpdate}
      />
      <Center marginTop="2rem" flexDirection="column">
        <HeaderAccountInfo />
        <Box display={"flex"} gap={"10px"} marginRight={"11em"}>
          <CardDescription
            description={"Total de categorias cadastradas"}
            infoCount={countCategorias}
          />

          <CardDescription
            description={"Total de Subcategorias cadastradas"}
            infoCount={countSubCategorias}
          />
        </Box>
      </Center>
      <Flex
        bg="white"
        margin="3rem 1rem 1rem 18em"
        borderRadius="6.32px"
        padding="2rem"
        flexDirection="column"
        width={"77%"}
      >
        <TableHeader
          Title={"Cadastro de Categorias"}
          Subtitle={"Listagem de cadastros"}
          setSearchQuery={setSearchQuery}
          ButtonOnClick={() => history.push("/dashboard/cadastrarcategoria")}
        />
        <Table>
          <Thead>
            <Tr color="#000">
              <Td textAlign="center"></Td>
              <Td textAlign="center">Nome</Td>
              <Td textAlign="center">Subcategorias</Td>
              <Td textAlign="center">Listagem de subcategorias</Td>
              <Td textAlign="center">Status</Td>
            </Tr>
          </Thead>
          <Tbody>
            {categorias?.map((category, index) => (
              <Tr key={index} color="#000">
                <Td display="flex" align="center" justify="center">
                  <Button
                    bg="transparent"
                    onClick={() => {
                      setShowModalEdit(true);
                      setCategoriaSelected(category);
                    }}
                  >
                    <Image src={iconeEdicao} />
                  </Button>

                  <Button
                    bg="transparent"
                    onClick={() => {
                      setShowModalDelete(true);
                      setCategoriaSelected(category);
                    }}
                  >
                    <Image src={iconeExcluir} />
                  </Button>
                </Td>
                <Td textAlign="center">{category.name}</Td>
                <Td textAlign="center">{category.Subcategoria.length}</Td>
                <Td textAlign="center">
                  <Button
                    bg="transparent"
                    onClick={() => {
                      setCategoriaSelected(category);
                      setShowModalSubcategories(true);
                    }}
                  >
                    Ver subcategorias
                  </Button>
                </Td>
                <Td textAlign="center">
                  {category.status === 1 ? "ATIVO" : "INATIVO"}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination page={page} setPage={setPage} count={count}></Pagination>
      </Flex>
      {/* {showModalEdit && (
        <ModalEdit
          setShowModalEdit={setShowModalEdit}
          categoria={categoriaSelected}
          loading={loadingCategorias}
        />
      )}
      {showModalDelete && (
        <ModalDelete
          setShowModalDelete={setShowModalDelete}
          categoria={categoriaSelected}
          loading={loadingCategorias}
        />
      )}
      {showModalSubcategories && (
        <ModalSubCategories
          setShowModalSubcategories={setShowModalSubcategories}
          categoria={categoriaSelected}
        />
      )}

      <div className="Container-geral">
        <div className="infos">
          <div className="titulo-page">
            <h1>Cadastro Categorias</h1>
            <span>Listagem de cadastros</span>
          </div>

          <input
            className="inputPesquisa"
            type="text"
            placeholder="Pesquisar..."
            onChange={(e) => setSearchQuery(e.target.value)}
          ></input>
          <a href="/dashboard/cadastrarcategoria">
            <button className="btn-cadastro" type="button">
              Cadastrar Novo
            </button>
          </a>
        </div>
        <Container>
          <tr className="titulos-table">
            <td></td>
            <td>Nome</td>
            <td>Subcategoria</td>
            <td>Listagem de Subcategorias</td>
            <td>Status</td>
          </tr>
          {categorias?.map((categoria, index) => (
            <Content key={index}>
              <td>
                <button
                  onClick={() => {
                    setShowModalEdit(true)
                    setCategoriaSelected(categoria)
                  }}
                >
                  <img src={iconeEdicao} />
                </button>

                <button
                  onClick={() => {
                    setShowModalDelete(true)
                    setCategoriaSelected(categoria)
                  }}
                >
                  <img src={iconeExcluir} />
                </button>
              </td>

              <td>{categoria.name}</td>
              <td>{categoria.Subcategoria.length}</td>
              <td
                onClick={() => {
                  setCategoriaSelected(categoria)
                  listSubcategories(categoria.Subcategoria)
                }}
              >
                <a href="#">Listagem</a>
              </td>
              <td>{categoria.ativo}</td>
            </Content>
          ))}
        </Container>
      </div> */}
    </>
  );
}

export default ListagemCategorias;
