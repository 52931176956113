import React, { useState, useEffect, useContext } from "react";
import iconeEdicao from "../../assets/images/iconeEdicao.svg";
import iconeExcluir from "../../assets/images/iconeExcluir.svg";
import { format, parseISO } from "date-fns";
import ModalDelete from "./ModalDelete";
import ModalEdit from "./ModalEdit";
import api from "../../services/api";
import "../../styles/global.css";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Spinner,
  Heading,
} from "@chakra-ui/react";
import { Flex, Center, Button, Image } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";

import TableHeader from "../../components/TableHeader";
import Pagination from "../../components/Pagination";
import ModalImage from "./modalImage";
import HeaderAccountInfo from "../../components/Header";
import CardDescription from "../../components/CardDescription";
import { AuthContext } from "../../context/UserContext";

function ListagemEvents() {
  let history = useHistory();

  const [showModalEdit, setShowModalEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalImage, setShowModalImage] = useState(false);
  const [update, setUpdate] = useState(0);
  const [events, setEvents] = useState([]);
  const [eventSelected, setEventSelected] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [countEvento, setCountEvento] = useState("");

  const loadingEvents = async () => {
    try {
      const { data: eventos } = await api.get(
        `/events?query=${searchQuery}&page=${page}`
      );
      setEvents(eventos.rows);
      setCount(eventos.count);
    } catch (err) {
      console.log(err);
    }
  };
  const countEvents = async () => {
    try {
      const response = await api.get(`/countEvents`);
      setCountEvento(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {}, [searchQuery]);
  useEffect(() => {
    loadingEvents();
    countEvents();
  }, []);

  useEffect(() => {
    loadingEvents();
  }, [searchQuery, page, update]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <ModalImage
        event={eventSelected}
        setShowModalImage={setShowModalImage}
        isOpenImage={showModalImage}
      />
      <ModalEdit
        isOpenEdit={showModalEdit}
        setShowModalEdit={setShowModalEdit}
        event={eventSelected}
        loading={loadingEvents}
        update={update}
        setUpdate={setUpdate}
      />
      {showModalDelete && (
        <ModalDelete
          setShowModalDelete={setShowModalDelete}
          event={eventSelected}
          loading={loadingEvents}
          update={update}
          setUpdate={setUpdate}
        />
      )}
      <Center marginTop="2rem" flexDirection="column">
        <HeaderAccountInfo />

        <CardDescription
          description={"Total de Eventos Cadastrados"}
          infoCount={countEvento}
        />
      </Center>
      <Flex
        bg="white"
        margin="3rem 1rem 1rem 18em"
        borderRadius="6.32px"
        padding="2rem"
        flexDirection="column"
        width={"77%"}
      >
        <TableHeader
          Title={"Cadastro de Eventos"}
          Subtitle={"Listagem de cadastros"}
          setSearchQuery={setSearchQuery}
          ButtonOnClick={() => history.push("/dashboard/cadastrarevento")}
        />
        <Table>
          <Thead>
            <Tr color="#000">
              <Td></Td>
              <Td>Nome</Td>
              <Td>Data do evento</Td>
              <Td>Status</Td>
              <Td>Imagem</Td>
            </Tr>
          </Thead>
          {isLoading ? (
            <Flex
              height={"250px"}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"20px"}
              marginLeft={"15em"}
            >
              <Heading fontSize={"0.9em"} color="#000">
                Carregando eventos
              </Heading>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                height={"100px"}
                width={"100px"}
              />
            </Flex>
          ) : (
            <Tbody
              as={motion.tbody}
              animate={{ opacity: [0, 1] }}
              transition={{
                duration: 2,
                ease: "linear",
              }}
            >
              {events?.map((event, index) => (
                <Tr key={index}>
                  <Td>
                    <Button
                      bg="transparent"
                      onClick={() => {
                        setShowModalEdit(true);
                        setEventSelected(event);
                      }}
                    >
                      <Image src={iconeEdicao} />
                    </Button>

                    <Button
                      bg="transparent"
                      onClick={() => {
                        setShowModalDelete(true);
                        setEventSelected(event);
                      }}
                    >
                      <Image src={iconeExcluir} />
                    </Button>
                  </Td>
                  <Td color="#000">{event.name}</Td>
                  <Td color="#000">
                    {format(parseISO(event.date), "dd/MM/yyyy")}
                  </Td>
                  <Td color="#000">
                    {event.status === 1 ? "ATIVO" : "INATIVO"}
                  </Td>
                  <Td
                    color="#000"
                    onClick={() => {
                      setEventSelected(event);
                      setShowModalImage(true);
                    }}
                  >
                    <Button bg="transparent">Ver Imagem</Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          )}
        </Table>
        <Pagination page={page} setPage={setPage} count={count}></Pagination>
      </Flex>
    </>
  );
}

export default ListagemEvents;
