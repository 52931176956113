import React, { useState, useEffect } from "react";
import HeaderAccountInfo from "../../components/Header";
import iconeExcluir from "../../assets/images/iconeExcluir.svg";
import ModalDelete from "./ModalDelete";
import ModalEdit from "./ModalEdit";
import api from "../../services/api";
import "../../styles/global.css";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Td, Image } from "@chakra-ui/react";
import {
  Flex,
  Center,
  Text,
  Box,
  Button,
  Spacer,
  Link,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

import Pagination from "../../components/Pagination";
import ModalImage from "./modalImage";
import CardDescription from "../../components/CardDescription";

function ListagemOcorrencia() {
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalImage, setShowModalImage] = useState(false);
  const [update, setUpdate] = useState(0);
  const [ocorrencia, setOcorrencia] = useState([]);
  const [eventSelected, setEventSelected] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [countOcorrencias, setCountOcorrencias] = useState("");

  const loadingEvents = async () => {
    try {
      const { data: eventos } = await api.get(
        `/ocorrencia?query=${searchQuery}&page=${page}`
      );
      setOcorrencia(eventos.rows);
      setCount(eventos.count);
    } catch (err) {
      console.log(err);
    }
  };
  const countOcorrencia = async () => {
    try {
      const resp = await api.get(`/countOcorrencias`);
      setCountOcorrencias(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {}, [searchQuery]);
  useEffect(() => {
    loadingEvents();
    countOcorrencia();
  }, []);

  useEffect(() => {
    loadingEvents();
  }, [searchQuery, page, update]);

  return (
    <>
      <ModalImage
        event={eventSelected}
        setShowModalImage={setShowModalImage}
        isOpenImage={showModalImage}
      />
      <ModalEdit
        isOpenEdit={showModalEdit}
        setShowModalEdit={setShowModalEdit}
        event={eventSelected}
        loading={loadingEvents}
        update={update}
        setUpdate={setUpdate}
      />
      {showModalDelete && (
        <ModalDelete
          setShowModalDelete={setShowModalDelete}
          event={eventSelected}
          loading={loadingEvents}
          update={update}
          setUpdate={setUpdate}
        />
      )}
      <Center marginTop="2rem" flexDirection="column" position="relative">
        <HeaderAccountInfo />
        <CardDescription
          description={"Total de ocorrências cadastradas "}
          infoCount={countOcorrencias}
        />
      </Center>
      <Flex
        bg="white"
        margin="3rem 1rem 1rem 18em"
        borderRadius="6.32px"
        padding="2rem"
        flexDirection="column"
        width={"77%"}
      >
        <Flex>
          <Box minWidth="15rem">
            <Text fontWeight="bold" fontSize="18" color="#000">
              Lista de Ocorrências
            </Text>
          </Box>
          <Center marginLeft="1rem" w="100%">
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                fontSize="1.2em"
                children={<Search2Icon color="gray.600" />}
              />
              <Input
                width={"95%"}
                bg="gray.100"
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Pesquisar..."
                _placeholder={{
                  color: "#757575",
                }}
                color="#000"
              />
            </InputGroup>
          </Center>
        </Flex>
        <Table>
          <Thead>
            <Tr color="#000">
              <Td fontSize="0.8em" maxWidth="50px"></Td>
              <Td fontSize="0.8em" textAlign="center" minWidth="100px">
                E-mail
              </Td>
              <Td fontSize="0.8em" textAlign="center" minWidth="100px">
                Protocolo
              </Td>
              <Td fontSize="0.8em" textAlign="center" minWidth="100px">
                Evento
              </Td>
              <Td fontSize="0.8em" textAlign="center" minWidth="100px">
                Categoria
              </Td>
              <Td fontSize="0.8em" textAlign="center" minWidth="100px">
                Data
              </Td>
              <Td fontSize="0.8em" textAlign="center" minWidth="100px">
                Data de Preenchimento
              </Td>
              <Td fontSize="0.8em" textAlign="center" minWidth="100px">
                Baixar Documento
              </Td>
            </Tr>
          </Thead>
          <Tbody>
            {ocorrencia?.map((ocorenc, index) => (
              <Tr key={index} color="#000">
                <Td style={{ maxWidth: "80px" }}>
                  <Button
                    bg="transparent"
                    w="70px"
                    marginRight="0.2rem"
                    onClick={() => {
                      setShowModalDelete(true);
                      setEventSelected(ocorenc);
                    }}
                  >
                    <Image src={iconeExcluir} height="30px" width="30px" />
                  </Button>
                </Td>
                <Td style={{ maxWidth: "100px" }}>{ocorenc.email}</Td>
                <Td style={{ maxWidth: "130px" }}>{ocorenc.protocolo}</Td>
                <Td style={{ maxWidth: "100px" }}>{ocorenc.event}</Td>
                <Td style={{ maxWidth: "100px" }}>{ocorenc.tipoCategoria}</Td>
                {ocorenc?.date ? (
                  <Td style={{ maxWidth: "130px" }}>{ocorenc.date}</Td>
                ) : (
                  <Td style={{ maxWidth: "130px" }}>
                    {ocorenc.data_ocorrencia}
                  </Td>
                )}
                <Td>{ocorenc?.data_preenchimento}</Td>

                <Td style={{ minWidth: "200px" }}>
                  <Link
                    href={`${api.defaults.baseURL}/ocorrencia/showFile/${ocorenc?.id}`}
                    download
                    isExternal
                    bgColor="#e2e8f0"
                    fontWeight="bold"
                    padding="15px"
                    borderRadius="10px"
                    fontSize={"1em"}
                    width="100%"
                    textAlign="center"
                  >
                    Abrir Arquivo
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination page={page} setPage={setPage} count={count}></Pagination>
      </Flex>
    </>
  );
}

export default ListagemOcorrencia;
