import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../services/api";
import Lottie from "react-lottie";
import axios from "axios";

import animationSucess from "../../../src/assets/animations/95029-success.json";

import {
  ModalBackground,
  ModalForeground,
  CloseButton,
  ContainerInputs,
  TitleModal,
  MainContainer,
  ContainerButtons,
  SubmitButton,
} from "../ModalCadastroAnuncios/styles";

// COMPONENTS
import InputAnuncio from "../InputAnuncio";
import { FiUpload } from "react-icons/fi";
import { Flex, Select, Text, Box, keyframes } from "@chakra-ui/react";
import { IoMdArrowDropdown } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { motion } from "framer-motion";

// SCHEMA
import schema from "../ModalCadastroAnuncios/schema";

// MASKs
import { formatCurrency } from "../../utils/formatCurrency";
import { formatCNPJ, formatCPF } from "../../utils/formatIdentification";
import { toast } from "react-toastify";

const sessionsInput = [
  "Primeira seção: entre as telas 2 e 3",
  "Segunda seção: entre as telas 4 e 5",
  "Terceira seção: entre as telas 6 e 7",
  "Quarta seção: entre as telas 8 e 9",
  "Todas as seções",
];

const animationKeyFrames = keyframes`
   0% { transform: scale(0)  }
  100% { transform: scale(1) }
`;

const animation = `${animationKeyFrames} .45s ease-in-out normal`;

const ModalEditAnuncios = ({ refreshAdverts, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [updateVideo, setUpdateVideo] = useState(null);
  const [upload, setUpload] = useState("");
  const [fileInfo, setFileInfo] = useState({});
  const [isConfirmated, setIsConfirmated] = useState(false);
  const token = process.env.REACT_APP_TOKEN_ACCESS;

  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleEditAdvertById = async () => {
    try {
      const response = await api.get(`/advert/${id}`);
      setFileInfo({
        link: response?.data?.advert?.videoUrl,
        id: response?.data?.advert?.videoId,
      });
      setValue("anunciante", response?.data?.advert?.anunciante);
      setValue("identificacao", response?.data?.advert?.identificacao);
      setValue("valorPago", response?.data?.advert?.valorPago);
      setValue("validadeInicial", response?.data?.advert?.validadeInicial);
      setValue("validadeFinal", response?.data?.advert?.validadeFinal);
      setValue("secao", response?.data?.advert?.secao);
      setValue("status", response?.data?.advert?.status);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleEditAdvertById();
  }, []);

  const handleUpdateAdvert = async (data) => {
    try {
      const response = await api.put(`/advert/${id}`, data);
      toast.success(response?.data?.message);
      handleSubmitVideo();
      refreshAdverts();
      setIsOpen(false);
      setIsConfirmated(false);
      return response?.data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleRequestUpdateVideoVimeo = async () => {
    try {
      const body = {
        file_name: "Untitled",
        upload: {
          status: "in_progress",
          size: "",
          approach: "post",
        },
      };

      const response = await axios.post(
        `https://api.vimeo.com/videos/${fileInfo?.id}/versions`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/vnd.vimeo.*+json;version=3.4",
          },
        }
      );
      console.log(response?.data);
      setUpload(response?.data?.upload?.upload_link);

      if (response?.status === 201) {
        setIsConfirmated(!isConfirmated);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitVideo = async () => {
    if (updateVideo) {
      const formData = new FormData();
      formData.append("file_data", updateVideo);

      try {
        const response = await axios.post(upload, formData);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  console.log(fileInfo);

  return (
    <>
      <FiEdit
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        color="#306F9D"
        style={{
          padding: "5px",
          fontSize: "2em",
          cursor: "pointer",
          marginLeft: "8px",
        }}
      />

      {isOpen ? (
        <ModalBackground>
          <ModalForeground>
            <TitleModal>Edição de Anúncios</TitleModal>
            <form
              onSubmit={handleSubmit(handleUpdateAdvert)}
              action={upload}
              encType="multipart/form-data"
              method="POST"
            >
              <MainContainer>
                <ContainerInputs
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <InputAnuncio
                    label={"Anunciante"}
                    width={"500px"}
                    placeholder="Digite o nome do anunciante..."
                    {...register("anunciante")}
                    error={errors?.anunciante?.message}
                    left="10px"
                  />

                  <InputAnuncio
                    label={"CPF/CNPJ"}
                    width={"500px"}
                    placeholder="Digite o CPF/CNPJ..."
                    right="20px"
                    {...register("identificacao")}
                    error={errors.identificacao?.message}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d]/g, "");

                      if (value.length === 11) {
                        setValue("identificacao", formatCPF(value));
                        clearErrors("identificacao");
                      } else if (value.length === 14) {
                        setValue("identificacao", formatCNPJ(value));
                        clearErrors("identificacao");
                      }
                    }}
                    maxLength={18}
                  />
                </ContainerInputs>

                <ContainerInputs>
                  <InputAnuncio
                    type="text"
                    label={"Valor pago"}
                    left="10px"
                    width={"500px"}
                    placeholder="Digite o valor pago..."
                    {...register("valorPago")}
                    error={errors?.valorPago?.message}
                    onChange={(e) => {
                      if (e.target.value?.length > 0) {
                        clearErrors("valorPago");
                      }
                      setValue("valorPago", formatCurrency(e.target.value));
                    }}
                  />

                  <InputAnuncio
                    type="date"
                    label={"Validade (data inicial)"}
                    right="3px"
                    width={"240px"}
                    placeholder="Digite a data inicial..."
                    error={errors?.validadeInicial?.message}
                    {...register("validadeInicial")}
                  />

                  <InputAnuncio
                    type="date"
                    label={"Validade (data final)"}
                    width={"240px"}
                    right="3px"
                    error={errors?.validadeFinal?.message}
                    placeholder="Digite a data final..."
                    {...register("validadeFinal")}
                  />
                </ContainerInputs>

                <ContainerInputs>
                  <Flex position={"relative"}>
                    <Text
                      color={"#000"}
                      position={"absolute"}
                      top="-25px"
                      fontSize={"15px"}
                      fontWeight={400}
                    >
                      Seção
                    </Text>
                    <Select
                      boxShadow="0 4px 4px rgba(0,0,0,0.10)"
                      border={"2px solid"}
                      borderColor={"#D0D0D0"}
                      width={"500px"}
                      height={"58px"}
                      position={"relative"}
                      borderRadius={"10px"}
                      color={"#000"}
                      {...register("secao")}
                    >
                      <option
                        value={""}
                        style={{
                          backgroundColor: "#fff",
                          color: "#000",
                        }}
                      >
                        Selecione...
                      </option>
                      {sessionsInput?.map((item, index) => {
                        return (
                          <option
                            key={index}
                            value={item}
                            style={{
                              color: "#000",
                              backgroundColor: "#fff",
                            }}
                          >
                            {item}
                          </option>
                        );
                      })}
                    </Select>

                    {errors.secao && errors?.secao?.message ? (
                      <Box
                        as={motion.div}
                        animation={animation}
                        top="-55px"
                        left="10px"
                        padding="5px 10px"
                        height={"30px"}
                        borderRadius={"10px"}
                        position={"absolute"}
                        bgColor={"#f10000"}
                        fontSize={"0.75em"}
                        fontWeight={"bold"}
                        bottom={"5px"}
                        color={"#fff"}
                        textAlign="center"
                        zIndex={"1000"}
                      >
                        <Text>{errors?.secao?.message}</Text>
                        <IoMdArrowDropdown
                          style={{
                            color: "#f10000",
                            fontSize: "3em",
                            position: "absolute",
                            bottom: "-20px",
                            right: "10px",
                          }}
                        />
                      </Box>
                    ) : (
                      false
                    )}
                  </Flex>

                  <Flex position={"relative"}>
                    <Text
                      color={"#000"}
                      position={"absolute"}
                      top="-25px"
                      fontSize={"15px"}
                      fontWeight={400}
                    >
                      Status
                    </Text>
                    <Select
                      boxShadow="0 4px 4px rgba(0,0,0,0.10)"
                      border={"2px solid"}
                      borderColor={"#D0D0D0"}
                      width={"500px"}
                      height={"58px"}
                      position={"relative"}
                      borderRadius={"10px"}
                      color={"#000"}
                      {...register("status")}
                      error={errors.status?.message}
                    >
                      <option
                        value={""}
                        style={{
                          backgroundColor: "#fff",
                          color: "#000",
                        }}
                      >
                        Selecione...
                      </option>
                      <option
                        value={"Ativo"}
                        color="#000"
                        style={{
                          backgroundColor: "#fff",
                          color: "#000",
                        }}
                      >
                        Ativo
                      </option>
                      <option
                        value={"Inativo"}
                        color="#000"
                        style={{
                          backgroundColor: "#fff",
                          color: "#000",
                        }}
                      >
                        Inativo
                      </option>
                    </Select>

                    {errors.status && errors?.status?.message ? (
                      <Box
                        as={motion.div}
                        animation={animation}
                        top="-55px"
                        right="3px"
                        padding="5px 10px"
                        height={"30px"}
                        borderRadius={"10px"}
                        position={"absolute"}
                        bgColor={"#f10000"}
                        fontSize={"0.75em"}
                        fontWeight={"bold"}
                        bottom={"5px"}
                        color={"#fff"}
                        textAlign="center"
                        zIndex={"1000"}
                      >
                        <Text>{errors?.status?.message}</Text>
                        <IoMdArrowDropdown
                          style={{
                            color: "#f10000",
                            fontSize: "3em",
                            position: "absolute",
                            bottom: "-20px",
                            right: "10px",
                          }}
                        />
                      </Box>
                    ) : (
                      false
                    )}
                  </Flex>
                </ContainerInputs>
              </MainContainer>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <label
                  htmlFor="file_data"
                  style={{
                    marginTop: "20px",
                    backgroundColor: "#EEEEEE",
                    color: "#000",
                    fontWeight: 400,
                    width: "240px",
                    borderRadius: "10px",
                    fontSize: "11px",
                    padding: "15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    boxShadow: "0 4px 4px rgba(0,0,0,0.25)",
                  }}
                >
                  <FiUpload
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#306F9D",
                      fontSize: "2.5em",
                      color: "#fff",
                      padding: "3px",
                      marginRight: "10px",
                    }}
                  />
                  {updateVideo ? updateVideo?.name : fileInfo?.link}
                </label>

                <input
                  accept="video/*"
                  type="file"
                  name="file_data"
                  id="file_data"
                  style={{
                    display: "none",
                  }}
                  onChange={(e) => {
                    if (e.target.files) {
                      setUpdateVideo(e.target.files[0]);
                    }
                  }}
                />

                <button
                  onClick={() => handleRequestUpdateVideoVimeo()}
                  type="button"
                  style={{
                    marginTop: "22px",
                    cursor: "pointer",
                    color: " #fff",
                    backgroundColor: "#306f9d",
                    border: "1px solid #306f9d",
                    padding: "5px 25px",
                    borderRadius: "10px",
                    fontWeight: 700,
                    fontSize: "0.8em",
                    width: "200px",
                    height: "50px",
                    textAlign: "left",
                    position: "relative",
                  }}
                >
                  {!isConfirmated ? (
                    "Confirmar edição"
                  ) : (
                    <>
                      <div>
                        Edição confirmada
                        <Lottie
                          style={{
                            top: "5px",
                            right: "20px",
                            position: "absolute",
                            height: "35px",
                            width: "35px",
                          }}
                          options={{
                            animationData: animationSucess,
                            loop: false,
                          }}
                        />
                      </div>
                    </>
                  )}
                </button>
              </div>

              <ContainerButtons>
                <CloseButton
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Cancelar
                </CloseButton>
                {isSubmitting ? (
                  <SubmitButton backgroundColor="#2da02b">
                    Atualizando...
                  </SubmitButton>
                ) : (
                  <SubmitButton>Atualizar</SubmitButton>
                )}
              </ContainerButtons>
            </form>
          </ModalForeground>
        </ModalBackground>
      ) : (
        false
      )}
    </>
  );
};

export default ModalEditAnuncios;
