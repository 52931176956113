import styled from "styled-components";
import colors from "../../../../../styles/colors";
import fonts from "../../../../../styles/fonts";

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width:100vw;
    background-color: rgba(20,0,0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white};
    width: 700px;
    height: 400px;
    border-radius: 30px;
    padding-right: 60px;
    h1 {
        ${fonts.notoSans700}
        color: ${colors.DarkBlue};
        margin-top: 60px;
        text-align: center;
    }

    a {
        text-decoration: none;
        margin-top: 20px;
        ${fonts.Mulish400};
        color: ${colors.DarkBlue};
        font-weight: normal;
        :hover {
            color: ${colors.lightBlue};

        }
    }
`

export const Content = styled.div`
    margin-left: 50px;
    
    > div { 
        display: flex;
        flex-direction: row;
        align-self: center;
        width: 100%;
        align-items: center;
        justify-content: center;
        
    }
`

export const ButtonSecundary = styled.button `
    background-color: ${colors.DarkBlue};
    color: #fff;
    width: 30%;
    height: 51.3px;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: 15px;
    border: none;
    border-radius: 11px;
    transition: background-color 0.2s;
    cursor: pointer;

    ${fonts.notoSans700};
    font-size: 18px;
    padding: 10px;
    text-align: center;
    
    &:hover{
        background-color: ${colors.lightBlue};
        
    }

`;

