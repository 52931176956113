import React, { useContext } from 'react'
import api from '../../../../../services/api'
import { toast } from 'react-toastify'

import { Flex, Center, Text, Box, Button, Spacer } from '@chakra-ui/react'

import { Container, Content, Body, ButtonSecundary } from './styles'

function ModalDelete({ setShowModalDelete, subcategoria, loading, update, setUpdate }) {
  // const { deleteEvents } = useContext(EventContext);
  const deleteSubcategoria = async (id) => {
    try {
      const { data } = await api.delete(`/subcategorias/${id}`)
      console.log(id)
      toast.success(data.message)
      setUpdate(update + 1)
      setShowModalDelete(false)
      return data
    } catch (err) {
      console.log(err)
      toast.error(
        err?.response?.data?.error ||
          'Ops... ocorreu um erro ao excluir a subcategoria.',
      )
      return false
    }
  }

  return (
    <Container>
      <Body>
        <Content>
          <Text fontSize="22" mb="2rem">
            Voce realmente deseja excluir a Subcategoria?
          </Text>
          <div>
            <Button
              bg="#162A35"
              color="white"
              w="40%"
              mr="1rem"
              size="lg"
              onClick={async () => {
                await deleteSubcategoria(subcategoria?.id)
                await loading()
              }}
            >
              CONFIRMAR
            </Button>

            <Button size="lg" w="40%" onClick={() => setShowModalDelete(false)}>
              Não
            </Button>
          </div>
        </Content>
      </Body>
    </Container>
  )
}

export default ModalDelete
