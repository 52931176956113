import React from "react";
import { ReactComponent as IconeGrafico } from "../../../assets/images/iconeGrafico.svg";

import { Container } from "./styles";

function ButtonList({ icon, isActive, title, ...rest }) {
  return (
    <Container isActive={isActive} {...rest}>
      <div className="bg-icone">{icon}</div>
      {title}
    </Container>
  );
}

export default ButtonList;
