import React, { useEffect } from "react";
import { Container, ContainerLogout, Content } from "./styles";
import Logo from "../../../assets/images/logo.svg";
import { routes } from "../../../routes";
import ButtonList from "../ButtonList";
import { getNivel } from "../../../services/auth";
import { useHistory } from "react-router-dom";
import { Switch, useToast } from "@chakra-ui/react";
import { MdOutlineLogout } from "react-icons/md";
import { AuthContext } from "../../../context/UserContext";
import { useContext } from "react";
import api from "../../../services/api";

function SideBar({ ativado }) {
  const { signOut } = useContext(AuthContext);
  const [isChecked, setIsChecked] = React.useState();
  const history = useHistory();
  const toast = useToast();
  const nivel = getNivel();

  useEffect(() => {
    async function getStatusCommunication() {
      try {
        const response = await api.get("/statusCommunication/1");
        setIsChecked(response?.data?.status);
      } catch (err) {
        console.log(err);
      }
    }
    getStatusCommunication();
  }, []);

  const handleChangeStateCommunication = async () => {
    const newValueUpdated = !isChecked;

    setIsChecked(newValueUpdated);

    if (newValueUpdated) {
      toast({
        description: "Comunicação ativada com sucesso!",
        status: "success",
        position: "top-right",
      });
    } else {
      toast({
        description: "Comunicação desativada com sucesso!",
        status: "success",
        position: "top-right",
      });
    }

    try {
      const response = await api.patch("/updateAuthorization", {
        activeCommunication: newValueUpdated,
      });

      return response?.data;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <img src={Logo} alt="logo" />

      <Content>
        {routes.protected.map(
          (route, index) =>
            route?.role?.find((elemento) => elemento == nivel) &&
            route.sideBar == true && (
              <ButtonList
                isActive={ativado === route.title}
                icon={route.icon}
                title={route.title}
                onClick={() => history.push(route.path)}
              />
            )
        )}
      </Content>

      <ContainerLogout
        style={{
          bottom: "66px",
        }}
      >
        <h1
          style={{
            color: "#FFFFFF",
            fontWeight: 400,
            fontSize: "12px",
          }}
        >
          Informe às autoridades
        </h1>

        <Switch
          colorScheme="gray"
          size="lg"
          padding={1}
          borderRadius={15}
          isChecked={isChecked}
          onChange={() => {
            handleChangeStateCommunication();
          }}
        />
      </ContainerLogout>

      <ContainerLogout onClick={() => signOut()}>
        <h1
          style={{
            color: "#FFFFFF",
            fontWeight: 400,
          }}
        >
          Sair
        </h1>
        <MdOutlineLogout color="#fff" fontSize="2em" />
      </ContainerLogout>
    </Container>
  );
}

export default SideBar;
