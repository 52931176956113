import React, { useRef, useState, useEffect } from 'react'
// import { EventContext } from '../../../context/EventContext'
// import getValidationErrors from '../../../utils/getValidationErrors'
// import Button from '../../../components/Button'
// import updateEvents from '../../../context/EventContext'
import api from '../../../../services/api'
import { toast } from 'react-toastify'
// import * as Yup from 'yup'
// import { useField } from '@unform/core'
// import Input from '../../../components/Input'
// import Select from '../../../components/select'

// import { Container, Forms, Body } from './styles'

import '../../../../styles/global.css'
// import InputSmall from '../../../components/InputMenor'
// import { useHistory } from 'react-router-dom'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  Input,
  Select,
  Text,
  Button,
  Flex,
  Image,
} from '@chakra-ui/react'
import { Grid, GridItem } from '@chakra-ui/react'
import { EditIcon } from '@chakra-ui/icons'

function ModalEdit({
  setShowModalEdit,
  categoria,
  isOpenEdit,
  setUpdate,
  update,
}) {
  const [name, setName] = useState(categoria?.name)
  const [status, setStatus] = useState(categoria?.status)

  //const {  updateEvents } = useContext(EventContext);
  const updateCategorias = async () => {
    try {
      const formData = new FormData()
      formData.append('name', name ? name : categoria?.name)
      formData.append('status', status ? status : categoria?.status)

      const response = await api.put(`/categorias/${categoria?.id}`, formData)
      console.log(response)
      setUpdate(update + 1)
      toast('Categoria editada com sucesso', 'sucess')
      setShowModalEdit(false)
      return response.data
    } catch (err) {
      console.log(err)
      toast.error(
        err?.response?.data?.error ||
          'Ops... ocorreu um erro ao editar a categoria.',
      )
      return false
    }
  }
  // const handleSubmit = async (data) => {
  //   try {
  //     formRef.current?.setErrors({})
  //     const schema = Yup.object().shape({
  //       name: Yup.string().required('Nome obrigatório'),
  //       date: Yup.date().required('Data obrigatória'),
  //       // imageEvent: Yup.string().required("Imagem do evento obrigatória"),
  //       status: Yup.number().required(
  //         'Por favor digite 1 para eventos ativos ou 0 para inativos',
  //       ),
  //     })
  //     await schema.validate(data, {
  //       abortEarly: false,
  //     })
  //     //formRef.current?.setErrors({});

  //     const resp = await updateEvents(data, event?.id)
  //     console.log(resp)
  //     loading()
  //     setShowModalEdit(false)
  //   } catch (err) {
  //     console.log(err)
  //     const errors = getValidationErrors(err)
  //     formRef.current?.setErrors(errors)
  //     console.log(errors)
  //   }
  // }
  return (
    <Modal size="4xl" isOpen={isOpenEdit} onClose={setShowModalEdit} isCentered>
      <ModalOverlay />
      <ModalContent textAlign="center">
        <ModalHeader>Edição de Categoria</ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(2, 2fr)" gap={6}>
            <GridItem textAlign="start" w="100%">
              <Text mb="0.5rem">Nome da Categoria:</Text>
              <Input
                defaultValue={categoria?.name}
                name="name"
                placeholder="Nome da Categoria"
                onChange={(e) => setName(e.target.value)}
              />
            </GridItem>
            <GridItem w="100%">
              <Text mb="0.5rem">Status da Categoria:</Text>
              <Select
                onChange={(e) => setStatus(e.target.value)}
                defaultValue={categoria?.status}
              >
                <option value="1">Ativo</option>
                <option value="0">Inativo</option>
              </Select>
            </GridItem>
          </Grid>
          <Center mt="3rem" mb="3rem">
            <Button
              bg="#162A35"
              color="white"
              w="40%"
              mr="1rem"
              size="lg"
              onClick={updateCategorias}
            >
              Confirmar
            </Button>
            <Button w="40%" size="lg" onClick={() => setShowModalEdit(false)}>
              Fechar
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
    //   <Container>
    //     <Body>
    //       <h1>Editar Evento</h1>
    //       <img width="100%" src={event.imageEvent} />

    //       <Forms ref={formRef} onSubmit={handleSubmit} initialData={{ ...event }}>
    //         <div>
    //           <span>Nome do Evento</span>
    //           <Input
    //             defaultValue={event.name}
    //             name="name"
    //             placeholder="Nome do evento"
    //           />
    //         </div>
    //         <div>
    //           <span>Data</span>
    //           <Input
    //             defaultValue={event.date}
    //             name="date"
    //             placeholder="Data do evento"
    //           />
    //         </div>
    //         <div className="conjunto-img-add3">
    //           <span>Imagem do evento</span>
    //           <InputSmall
    //             type="file"
    //             name="imageNew"
    //             onChange={(e) => setImageEvent(e.target.files[0])}
    //             placeholder="Imagem do evento"
    //           />
    //           <input class="add-img" type="button"></input>
    //         </div>
    //         <div>
    //           <span>Status</span>
    //           <Select
    //             defaultValue={event.status}
    //             name="status"
    //             placeholder="Status do Evento"
    //           />
    //         </div>

    //         <div className="botao-edit3">
    //           <button type="submit">CONFIRMAR</button>

    //           <a href="#" onClick={() => setShowModalEdit(false)}>
    //             Voltar
    //           </a>
    //         </div>
    //       </Forms>
    //     </Body>
    //   </Container>
  )
}

export default ModalEdit
