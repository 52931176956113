import React, { createContext } from "react";
import { toast } from "react-toastify";
import api from "../services/api";
import { getId, login, logout } from "../services/auth";

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const loadingUser = async () => {
    try {
      const id = getId();
      const { data } = await api.get(`/users/${id}`);
      return data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const singIn = async (credenciais) => {
    try {
      const response = await api.post("/login", credenciais);

      if (
        response.data?.user?.acesso == 1 ||
        response?.data?.user?.acesso == 2
      ) {
        login(
          response.data?.token,
          response.data?.user?.nivel,
          response.data?.user?.id,
          response.data?.user?.active_communication
        );

        if (response?.status === 200) {
          window.location.replace("/dashboard/cadastroeventos");
        }
        toast.success("Bem Vindo!");

        return true;
      } else {
        toast.error("Usuário não autorizado");
        return false;
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... ocorreu um erro");
      return false;
    }
  };

  const signOut = () => {
    logout();
    window.location.replace("/");
  };

  return (
    <AuthContext.Provider
      value={{
        loadingUser,
        singIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
