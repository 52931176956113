import styled from "styled-components";
import colors from "../../../styles/colors";
import fonts from "../../../styles/fonts";

//const { white, darkblue, ligthGray } = colors;
//const { notoSans400 } = fonts;

export const Container = styled.div`
  min-width: 274px;
  width: 274px;
  background-color: ${colors.white};
  gap: 30px;
  ${fonts.notoSans400}

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  z-index: 1000;
  height: 100%;

  > img {
    align-self: center;
    margin-top: 35px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 2px;
  margin-top: 20px;

  img {
    margin-right: 12px;
    padding: 0px;
  }
`;

export const ContainerLogout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: 0.5s;
  width: 250px;
  padding: 3px;
  position: absolute;
  bottom: 10px;
  left: 12px;
  border-radius: 10px;
  background-color: #306f9d;
  padding: 5px;

  :hover {
    background-color: #306f9d;

    > h1 {
      color: #fff;
    }
  }
`;

export const MainContainerSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 65%;
  gap: 10px;
  cursor: ointer;
`;

export const ContainerSwitch = styled.div`
  padding: 10px;
  background-color: #162a35;
  position: absolute;
  top: -80px;
  right: -25px;
  animation: 0.2s ease-in easeInAnim;

  @keyframes easeInAnim {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const Title = styled.h1`
  color: #000;
  font-size: 15px;
  text-align: justify;
  width: 100px;
`;
