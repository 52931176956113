import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../services/api";
import axios from "axios";

import { IoMdArrowDropdown } from "react-icons/io";
import { motion } from "framer-motion";

import {
  ModalBackground,
  ModalForeground,
  CloseButton,
  ContainerInputs,
  TitleModal,
  MainContainer,
  ContainerButtons,
  SubmitButton,
  UploadButton,
} from "./styles";

// COMPONENTS
import InputAnuncio from "../InputAnuncio";
import { FiUpload } from "react-icons/fi";
import {
  Flex,
  Select,
  Text,
  Center,
  Button,
  Box,
  keyframes,
} from "@chakra-ui/react";

// SCHEMA
import schema from "./schema";

// MASKs
import { formatCurrency } from "../../utils/formatCurrency";
import { formatCNPJ, formatCPF } from "../../utils/formatIdentification";
import { toast } from "react-toastify";

const sessionsInput = [
  "Primeira seção: entre as telas 2 e 3",
  "Segunda seção: entre as telas 4 e 5",
  "Terceira seção: entre as telas 6 e 7",
  "Quarta seção: entre as telas 8 e 9",
  "Todas as seções",
];

const animationKeyFrames = keyframes`
   0% { transform: scale(0)  }
  100% { transform: scale(1) }
`;

const animation = `${animationKeyFrames} .45s ease-in-out normal`;

const ModalCadastroAnuncios = ({ refreshAdverts }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoId, setVideoId] = useState("");
  const [upload, setUpload] = useState("");

  const token = process.env.REACT_APP_TOKEN_ACCESS;

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      anunciante: "",
      identificacao: null,
      valorPago: "",
      validadeInicial: "",
      validadeFinal: "",
      secao: "",
      status: "",
      videoUrl: "",
      videoId: "",
    },
    resolver: yupResolver(schema),
  });

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleClearValuesInputs = () => {
    reset({
      anunciante: "",
      identificacao: "",
      secao: "",
      status: "",
      validadeInicial: "",
      validadeFinal: "",
      valorPago: "",
      videoUrl: "",
      videoId: "",
    });
    setFile(null);
  };

  const handleRequestPermissions = async () => {
    const body = {
      name: "Untitled",

      upload: {
        approach: "post",
        size: "",
        redirect_url: "",
      },
    };

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/vnd.vimeo.*+json;version=3.4",
      };

      const response = await axios.post(
        `https://api.vimeo.com/me/videos`,
        body,
        { headers }
      );
      const idVideo = response?.data?.link.split("/").pop();
      setVideoUrl(response?.data?.link);
      setVideoId(idVideo);
      setUpload(response?.data?.upload?.upload_link);

      return response?.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteVideoOfVimeo = async (id) => {
    try {
      const response = await axios.delete(
        `https://api.vimeo.com/videos/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitVideo = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file_data", file);

      try {
        const response = await axios.post(upload, formData);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleInsertNewDataAdvert = async (data) => {
    try {
      const response = await api.post("/advert", {
        anunciante: data?.anunciante,
        identificacao: data?.identificacao,
        status: data?.status,
        validadeInicial: data?.validadeInicial,
        validadeFinal: data?.validadeFinal,
        valorPago: data?.valorPago,
        secao: data?.secao,
        videoUrl: videoUrl,
        videoId: videoId,
      });
      toast.success(response?.data?.message);
      handleSubmitVideo();
      setIsOpen(false);
      handleClearValuesInputs();
      refreshAdverts();
      return response?.data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <>
      <Center marginLeft="1rem">
        <Button
          onClick={() => {
            setIsOpen(!isOpen);
            handleRequestPermissions();
          }}
          color="#000"
          bgColor="#EFF0F9"
          _hover={{
            backgroundColor: "#eaeaea",
          }}
        >
          Cadastrar Novo
        </Button>
      </Center>

      {isOpen ? (
        <ModalBackground>
          <ModalForeground>
            <TitleModal>Cadastro de Anúncios</TitleModal>
            <form
              onSubmit={handleSubmit(handleInsertNewDataAdvert)}
              action={upload}
              encType="multipart/form-data"
              method="POST"
            >
              <MainContainer>
                <ContainerInputs
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <InputAnuncio
                    label={"Anunciante"}
                    width={"500px"}
                    placeholder="Digite o nome do anunciante..."
                    {...register("anunciante")}
                    error={errors.anunciante?.message}
                    left="10px"
                  />

                  <InputAnuncio
                    label={"CPF/CNPJ"}
                    width={"500px"}
                    placeholder="Digite o CPF/CNPJ..."
                    right="20px"
                    {...register("identificacao")}
                    error={errors.identificacao?.message}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d]/g, "");

                      if (value.length === 11) {
                        setValue("identificacao", formatCPF(value));
                        clearErrors("identificacao");
                      } else if (value.length === 14) {
                        setValue("identificacao", formatCNPJ(value));
                        clearErrors("identificacao");
                      }
                    }}
                    maxLength={18}
                  />
                </ContainerInputs>

                <ContainerInputs>
                  <InputAnuncio
                    type="text"
                    label={"Valor pago"}
                    left="10px"
                    width={"500px"}
                    placeholder="Digite o valor pago..."
                    {...register("valorPago")}
                    error={errors?.valorPago?.message}
                    onChange={(e) => {
                      if (e.target.value?.length > 0) {
                        clearErrors("valorPago");
                      }
                      setValue("valorPago", formatCurrency(e.target.value));
                    }}
                  />

                  <InputAnuncio
                    type="date"
                    label={"Validade (data inicial)"}
                    right="3px"
                    width={"240px"}
                    placeholder="Digite a data inicial..."
                    error={errors?.validadeInicial?.message}
                    {...register("validadeInicial")}
                  />

                  <InputAnuncio
                    type="date"
                    label={"Validade (data final)"}
                    width={"240px"}
                    right="3px"
                    error={errors?.validadeFinal?.message}
                    placeholder="Digite a data final..."
                    {...register("validadeFinal")}
                  />
                </ContainerInputs>

                <ContainerInputs>
                  <Flex position={"relative"}>
                    <Text
                      color={"#000"}
                      position={"absolute"}
                      top="-25px"
                      fontSize={"15px"}
                      fontWeight={400}
                    >
                      Seção
                    </Text>
                    <Select
                      boxShadow="0 4px 4px rgba(0,0,0,0.10)"
                      border={"2px solid"}
                      borderColor={"#D0D0D0"}
                      width={"500px"}
                      height={"58px"}
                      position={"relative"}
                      borderRadius={"10px"}
                      color={"#000"}
                      {...register("secao")}
                    >
                      <option
                        value={""}
                        style={{
                          backgroundColor: "#fff",
                          color: "#000",
                        }}
                      >
                        Selecione...
                      </option>
                      {sessionsInput?.map((item, index) => {
                        return (
                          <option
                            key={index}
                            value={item}
                            style={{
                              color: "#000",
                              backgroundColor: "#fff",
                            }}
                          >
                            {item}
                          </option>
                        );
                      })}
                    </Select>

                    {errors.secao && errors?.secao?.message ? (
                      <Box
                        as={motion.div}
                        animation={animation}
                        top="-55px"
                        left="10px"
                        padding="5px 10px"
                        height={"30px"}
                        borderRadius={"10px"}
                        position={"absolute"}
                        bgColor={"#f10000"}
                        fontSize={"0.75em"}
                        fontWeight={"bold"}
                        bottom={"5px"}
                        color={"#fff"}
                        textAlign="center"
                        zIndex={"1000"}
                      >
                        <Text>{errors?.secao?.message}</Text>
                        <IoMdArrowDropdown
                          style={{
                            color: "#f10000",
                            fontSize: "3em",
                            position: "absolute",
                            bottom: "-20px",
                            right: "10px",
                          }}
                        />
                      </Box>
                    ) : (
                      false
                    )}
                  </Flex>

                  <Flex position={"relative"}>
                    <Text
                      color={"#000"}
                      position={"absolute"}
                      top="-25px"
                      fontSize={"15px"}
                      fontWeight={400}
                    >
                      Status
                    </Text>
                    <Select
                      boxShadow="0 4px 4px rgba(0,0,0,0.10)"
                      border={"2px solid"}
                      borderColor={"#D0D0D0"}
                      width={"500px"}
                      height={"58px"}
                      position={"relative"}
                      borderRadius={"10px"}
                      color={"#000"}
                      {...register("status")}
                      error={errors.status?.message}
                    >
                      <option
                        value={""}
                        style={{
                          backgroundColor: "#fff",
                          color: "#000",
                        }}
                      >
                        Selecione...
                      </option>
                      <option
                        value={"Ativo"}
                        color="#000"
                        style={{
                          backgroundColor: "#fff",
                          color: "#000",
                        }}
                      >
                        Ativo
                      </option>
                      <option
                        value={"Inativo"}
                        color="#000"
                        style={{
                          backgroundColor: "#fff",
                          color: "#000",
                        }}
                      >
                        Inativo
                      </option>
                    </Select>

                    {errors.status && errors?.status?.message ? (
                      <Box
                        as={motion.div}
                        animation={animation}
                        top="-55px"
                        right="3px"
                        padding="5px 10px"
                        height={"30px"}
                        borderRadius={"10px"}
                        position={"absolute"}
                        bgColor={"#f10000"}
                        fontSize={"0.75em"}
                        fontWeight={"bold"}
                        bottom={"5px"}
                        color={"#fff"}
                        textAlign="center"
                        zIndex={"1000"}
                      >
                        <Text>{errors?.status?.message}</Text>
                        <IoMdArrowDropdown
                          style={{
                            color: "#f10000",
                            fontSize: "3em",
                            position: "absolute",
                            bottom: "-20px",
                            right: "10px",
                          }}
                        />
                      </Box>
                    ) : (
                      false
                    )}
                  </Flex>
                </ContainerInputs>
              </MainContainer>

              <UploadButton
                htmlFor="file_data"
                style={{
                  marginTop: "20px",
                  backgroundColor: "#EEEEEE",
                  color: "#000",
                  fontWeight: 400,
                  width: "240px",
                  borderRadius: "10px",
                  fontSize: "11px",
                  padding: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  boxShadow: "0 4px 4px rgba(0,0,0,0.25)",
                }}
              >
                <FiUpload
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#306F9D",
                    fontSize: "2.5em",
                    color: "#fff",
                    padding: "3px",
                    marginRight: "10px",
                  }}
                />
                {file?.name ?? "Faça o upload do vídeo"}
              </UploadButton>
              <input
                accept="video/*"
                type="file"
                name="file_data"
                id="file_data"
                style={{
                  display: "none",
                }}
                onChange={handleFileChange}
              />

              <ContainerButtons>
                <CloseButton
                  onClick={() => {
                    setIsOpen(false);
                    handleClearValuesInputs();
                    handleDeleteVideoOfVimeo(videoId);
                  }}
                >
                  Cancelar
                </CloseButton>
                {isSubmitting ? (
                  <SubmitButton backgroundColor="#2da02b">
                    Enviando...
                  </SubmitButton>
                ) : (
                  <SubmitButton>Enviar</SubmitButton>
                )}
              </ContainerButtons>
            </form>
          </ModalForeground>
        </ModalBackground>
      ) : (
        false
      )}
    </>
  );
};

export default ModalCadastroAnuncios;
