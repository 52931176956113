import React from "react";
import { Route, Redirect } from "react-router-dom";
import SingIn from "./pages/SingIn";
import { getToken } from "./services/auth";
import ListagemEvents from "./layouts/ListEventos";
import ListagemCategorias from "./layouts/Dashboard/ListCategorias";
import ListagemOcorrencia from "./layouts/ListOcorrencia";
import { ReactComponent as IconeGrafico } from "./assets/images/iconeGrafico.svg";
import { ReactComponent as IconeConfetti } from "./assets/images/confete.svg";
import { ReactComponent as IconePasta } from "./assets/images/pasta.svg";
import Cadastrarevento from "./layouts/Dashboard/Cadastrarevento";
import Cadastrarcategoria from "./layouts/Dashboard/Cadastrarcategoria";
import CadastrarOcorrencia from "./layouts/Dashboard/CadastrarOcorrencia";
import Graficos from "./layouts/Dashboard/Graficos";
import ListAnuncios from "./layouts/Dashboard/Anuncios/ListAnuncios";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = getToken();

  return (
    <Route
      {...{ rest }}
      render={(props) =>
        token ? (
          <Component {...{ props }} />
        ) : (
          <Redirect
            to={{ pathname: "/", state: { from: props.location.pathname } }}
          />
        )
      }
    />
  );
};

export const routes = {
  protected: [
    {
      path: "/dashboard",
      title: "Relatórios",
      icon: <IconeGrafico />,
      sideBar: true,
      role: [1, 2],
      component: () => <Graficos />,
    },

    {
      path: "/dashboard/cadastroeventos",
      title: "Cadastro de eventos",
      icon: <IconeConfetti />,
      sideBar: true,
      role: [1, 2],
      component: () => <ListagemEvents />,
    },
    {
      path: "/dashboard/categorias",
      title: "Categorias",
      icon: <IconePasta />,
      sideBar: true,
      role: [1, 2],
      component: () => <ListagemCategorias />,
    },
    {
      path: "/dashboard/ocorrencia",
      title: "Ocorrências",
      icon: <IconePasta />,
      sideBar: true,
      role: [1, 2],
      component: () => <ListagemOcorrencia />,
    },

    {
      path: "/dashboard/cadastrarevento",
      title: "cadastrar evento",
      sideBar: false,
      role: [1, 2],
      component: () => <Cadastrarevento />,
    },

    {
      path: "/dashboard/cadastrarcategoria",
      title: "cadastrar evento",
      sideBar: false,
      role: [1, 2],
      component: () => <Cadastrarcategoria />,
    },
    {
      path: "/dashboard/cadastrarocorrencia",
      title: "cadastrar evento",
      sideBar: false,
      role: [1, 2],
      component: () => <CadastrarOcorrencia />,
    },

    {
      path: "/dashboard/listagemAnuncios",
      title: "Anúncios",
      sideBar: true,
      role: [1],
      icon: <IconePasta />,
      component: () => <ListAnuncios />,
    },
  ],
  public: [
    {
      path: "/",
      component: () => <SingIn />,
    },
  ],
};
