import * as yup from "yup";

const schema = yup.object({
  anunciante: yup.string().required("O nome do anunciante é obrigatório"),
  identificacao: yup.string().required("O CPF ou CNPJ é obrigatório !"),
  valorPago: yup.string().required("O valor pago é obrigatório !"),
  validadeInicial: yup.string().required("A validade inicial é obrigatória !"),
  validadeFinal: yup.string().required("A validade final é obrigatória !"),
  secao: yup.string().required("A seção é obrigatória !"),
  status: yup.string().required("O status é obrigatório !"),
});

export default schema;
