import styled from "styled-components";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";

const { white, gray, textBlue } = colors;
const { Mulish400, Mulish700 } = fonts;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 450px;
  background: ${white};
  border-radius: 10px;
  padding: 40px 0px;
  animation: moveSmoothLeft 1s normal;

  @keyframes moveSmoothLeft {
    0% {
      transform: translateX(-70px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  > div {
    display: flex;
    color: ${gray};
    ${Mulish400};

    > a {
      ${Mulish700};
      text-decoration: none;
      color: ${textBlue};
    }
  }
`;
