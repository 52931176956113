import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import "../../../styles/global.css";
import { Select, Flex, VStack, Heading, Box } from "@chakra-ui/react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import GraphicWithoutEvents from "../../../components/GraphicWithoutEvents";

function Graficos() {
  const [data, SetData] = useState([]);
  const [data2, SetData2] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  var randomColor = require("randomcolor");

  const getCount = async () => {
    try {
      const resp = await api.get("/countByCategoria");
      SetData(resp.data.totalGolpes);
    } catch (err) {
      console.log(err);
    }
  };
  const getEventos = async () => {
    try {
      const resp = await api.get("/allevents");
      setEventos(resp.data);
      setSelectedEvent(resp.data[0].id);
    } catch (err) {
      console.log(err);
    }
  };

  const getCountByEvent = async () => {
    try {
      const resp = await api.post("/countByEvent", {
        evento_id: selectedEvent,
      });
      SetData2(resp.data.totalCrimes);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCount();
    getEventos();
  }, []);
  useEffect(() => {
    getCountByEvent();
  }, [selectedEvent]);
  const RADIAN = Math.PI / 180;
  const RADIAN2 = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        fontWeight="bold"
        width={100}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {(percent * 100).toFixed(0) !== "0" && `${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const renderCustomizedLabel2 = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN2);
    const y = cy + radius * Math.sin(-midAngle * RADIAN2);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        fontWeight="bold"
        width={100}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {(percent * 100).toFixed(0) !== "0" && `${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <VStack
      bgColor="#fff"
      style={{
        width: "78%",
        marginTop: "20px",
        marginBottom: "20px",
        marginLeft: "18em",
        borderRadius: "0.5rem",
        minHeight: "calc(1350px * 2)",
        position: "relative",
        overflowY: "hidden",
      }}
    >
      <Heading mt="1.2rem" color="#000" fontWeight="500">
        Gráficos
      </Heading>

      <Box position="absolute" top="90px" left="40px">
        <Heading fontSize="1em" color="#000" fontWeight="400" mb="1.1rem">
          Total de crimes com base em um evento:
        </Heading>

        <Select
          width={350}
          onChange={(e) => {
            setSelectedEvent(e.target.value);
            console.log(selectedEvent);
          }}
          style={{
            color: "#000",
            border: "1px solid #000",
            cursor: "pointer",
          }}
        >
          {eventos.map((item) => {
            return (
              <>
                <option
                  value={item.id}
                  style={{
                    backgroundColor: "#fff",
                  }}
                >
                  {item.name}
                </option>
              </>
            );
          })}

          <option
            style={{
              backgroundColor: "#fff",
              textTransform: "uppercase",
            }}
          >
            Total de Ocorrências
          </option>

          <option
            style={{
              backgroundColor: "#fff",
              textTransform: "uppercase",
            }}
          >
            Total Fora de Eventos
          </option>
        </Select>
      </Box>

      <Flex
        position="absolute"
        top="250px"
        width="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box>
          <Heading fontSize="1em" color="#000" fontWeight="400">
            Total de Crimes :
          </Heading>

          <PieChart width={700} height={700}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={320}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={randomColor({
                    luminosity: "dark",
                  })}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </Box>

        <Box mt="1rem" position="relative">
          <Heading
            fontSize="1em"
            color="#000"
            fontWeight="400"
            position="absolute"
            top="50px"
          >
            Total de Crimes por Evento:
          </Heading>

          <PieChart width={800} height={800}>
            <Pie
              data={data2}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel2}
              outerRadius={320}
              dataKey="value"
            >
              {data2
                .filter((x) => x !== 0)
                .map((entry, index) => (
                  <>
                    ( (
                    <Cell
                      key={`cell-${index}`}
                      fill={randomColor({
                        luminosity: "dark",
                      })}
                    />
                    ))
                  </>
                ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </Box>

        <Box mt="1em">
          <Heading fontSize="1em" color="#000" fontWeight="400">
            Total Fora de Eventos :
          </Heading>

          <GraphicWithoutEvents />
        </Box>
      </Flex>
    </VStack>
  );
}
export default Graficos;
