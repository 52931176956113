import React from "react";

import { Flex, Center, Text, Box, Button, Spacer } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

export default function Pagination({ setPage, page, count, perPage = 10 }) {
  console.log(page);
  console.log(count / (page + 1));
  return (
    <Flex mt="auto">
      <Spacer />
      <Center>
        <Button
          mt={2}
          isDisabled={page <= 0}
          bg="transparent"
          color="#000"
          mr="1rem"
          onClick={() => setPage(page - 1)}
        >
          <ChevronLeftIcon color="#000" fontSize="2em" />
        </Button>
        <Text
          mt={2}
          color="#fff"
          bgColor="#162A35"
          padding={".5em 1em"}
          borderRadius={5}
        >
          {page + 1}
        </Text>
        <Button
          mt={2}
          isDisabled={count / (page + 1) < perPage}
          bg="transparent"
          color="#000"
          ml="1rem"
          onClick={() => setPage(page + 1)}
        >
          <ChevronRightIcon color="#000" fontSize="2em" />
        </Button>
      </Center>
    </Flex>
  );
}
