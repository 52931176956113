import React from "react";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { Flex, Center, Text, Box, Spacer } from "@chakra-ui/react";

export default function TableHeader({
  Title,
  Subtitle,
  setSearchQuery,
  ButtonOnClick,
  ButtonText = "Cadastrar Novo",
}) {
  return (
    <Flex>
      <Box minWidth="15rem">
        <Text fontWeight="bold" fontSize="18" color="#000" marginTop={"5px"}>
          {Title}
        </Text>
        <Text fontSize="12" color="#000">
          {Subtitle}
        </Text>
      </Box>
      <Center marginLeft="1rem" w="100%">
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            fontSize="1.2em"
            children={<Search2Icon color="gray.600" />}
          />
          <Input
            color="#000"
            bg="gray.100"
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Pesquisar..."
            _placeholder={{
              color: "#757575",
            }}
          />
        </InputGroup>
      </Center>
      <Spacer></Spacer>
      <>
        <Center marginLeft="1rem">
          <Button
            onClick={ButtonOnClick}
            color="#000"
            bgColor="#EFF0F9"
            _hover={{
              backgroundColor: "#eaeaea",
            }}
          >
            {ButtonText}
          </Button>
        </Center>
      </>
    </Flex>
  );
}
