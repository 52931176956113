import React, { useRef, useContext, useEffect, useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";
import "../../../styles/global.css";
import { useHistory } from "react-router-dom";

import { Center, Input, Select, Text, Button, Flex } from "@chakra-ui/react";
import { Grid, GridItem } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

function Cadastrarcategoria({ loading, categoria }) {
  const formRef = useRef(null);
  let history = useHistory();

  const [subCategoryArray, setSubCategoryArray] = useState([""]);
  const [name, setName] = useState("");
  const [status, setStatus] = useState(1);

  const handleSubmit = async () => {
    try {
      const filteredArray = subCategoryArray.filter((element) => {
        return element !== "";
      });
      if (name.length === 0 || filteredArray.length === 0) {
        toast.error("Preencha todos os campos para cadastrar a categoria.");
      }

      const response = await api.post(`/categorias`, {
        name,
        status,
        subCategoryArray: filteredArray,
      });

      toast.success("Criado com Sucesso");
      history.push("/dashboard/categorias");
      return response.data;
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.error ||
          "Ops... ocorreu um erro ao criar a categoria."
      );
      return false;
    }
  };

  const addSubCategory = async () => {
    let subcategories = [...subCategoryArray];
    subcategories.push("");
    setSubCategoryArray(subcategories);
  };

  const onChangeSubcategory = async (e) => {
    const value = e.target.value;
    const index = e.target.getAttribute("index");
    let subcategories = [...subCategoryArray];
    subcategories[index] = value;
    setSubCategoryArray(subcategories);
    formRef.current.setData("subcategorias", subcategories);
  };

  return (
    <Flex
      bg="white"
      margin="3rem 1rem 1rem 18em"
      borderRadius="6.32px"
      padding="2rem"
      flexDirection="column"
      width={"77%"}
    >
      <Text fontSize="4xl" align="center">
        Cadastro de Categoria
      </Text>
      <Grid mt="2rem" templateColumns="repeat(2, 2fr)" gap={6}>
        <GridItem textAlign="start" w="100%">
          <Text mb="0.5rem">Nome da Categoria:</Text>
          <Input
            defaultValue={categoria?.name}
            name="name"
            placeholder="Nome da categoria"
            onChange={(e) => setName(e.target.value)}
          />
        </GridItem>
        <GridItem w="100%">
          <Text mb="0.5rem">Status da Categoria:</Text>
          <Select
            onChange={(e) => setStatus(e.target.value)}
            defaultValue={categoria?.status}
          >
            <option value="1">Ativo</option>
            <option value="0">Inativo</option>
          </Select>
        </GridItem>
        <GridItem textAlign="start" w="100%">
          <Text mb="0.5rem">Subcategorias:</Text>
          {subCategoryArray.map((value, index) => (
            <Flex mb="1rem">
              <Input
                name="subcategoria"
                placeholder="Nome da subcategoria"
                onChange={onChangeSubcategory}
                index={index}
                mr="1rem"
              />
              <Button
                onClick={addSubCategory}
                bg="#162A35"
                w="1rem"
                borderRadius="50%"
                zIndex={index != subCategoryArray.length - 1 && -1}
              >
                <AddIcon color="white" />
              </Button>
            </Flex>
          ))}
        </GridItem>
      </Grid>
      <Center mt="3rem" mb="3rem">
        <Button
          bg="#162A35"
          color="white"
          w="40%"
          mr="1rem"
          size="lg"
          onClick={handleSubmit}
        >
          Cadastrar
        </Button>
        <Button
          w="40%"
          size="lg"
          onClick={() => history.push("/dashboard/categorias")}
        >
          Fechar
        </Button>
      </Center>
    </Flex>
    //     <Container>
    //     <Body>
    //         <h1>Cadastrar Categoria</h1>
    //         <Forms
    //              ref={formRef}
    //              onSubmit={handleSubmit}
    //              initialData={{...categoria}}
    //         >

    //         <div>
    //             <span>Nome da Categoria</span>
    //             <Input
    //             //defaultValue={event.name}
    //             name="name" placeholder="Nome da categoria"/>
    //         </div>

    //         <div className="conjunto-img-add2">
    //             <span>Subcategoria</span>
    //             {subCategoryArray.map((value, index) => (
    //                 <>
    //                 <InputSmall  name="subcategoria" placeholder="Nome da subcategoria" onChange={onChangeSubcategory} index={index}/>
    //                 {index == subCategoryArray.length - 1 && <input class="add-img" type="button" onClick={addSubCategory}></input>}
    //                 </>
    //             ))}
    //         </div>
    //         <div className="position">
    //             <span>Status</span>
    //             <Select
    //             //defaultValue={categoria.status}
    //              name="status" placeholder="Status da categoria"/>
    //         </div>

    //         <div className="botao-edit-categorias">
    //           <Button type="submit">Cadastrar</Button>
    //           <SecundaryButton><a href="/dashboard/categorias">Cancelar</a></SecundaryButton>
    //         </div>
    //         </Forms>
    //     </Body>
    // </Container>
  );
}

export default Cadastrarcategoria;
