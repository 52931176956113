const fonts = {
    Mulish400: {
        fontFamily: "Mulish",
        fontStyle: "normal",
        fontWeigth: 400,
        fontDisplay: "swap",
    },

    Mulish600: {
        fontFamily: "Mulish",
        fontStyle: "normal",
        fontWeigth: 600,
        fontDisplay: "swap",
    },

    Mulish700: {
        fontFamily: "Mulish",
        fontStyle: "normal",
        fontWeigth: 700,
        fontDisplay: "swap",
    },

    notoSans400: {
        fontFamily: "Noto Sans",
        fontStyle: "normal",
        fontWeigth: 400,
        fontDisplay: "swap",
    },
    notoSans700: {
        fontFamily: "Noto Sans",
        fontStyle: "Bold",
        fontWeigth: 700,
        fontDisplay: "swap",
    },
    Poppins400: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeigth: "400",
        fontDisplay: "swap",
    }
};


export default fonts;