import styled from 'styled-components';
import colors from '../../../styles/colors';
import fonts from '../../../styles/fonts';



export const Container = styled.button`
width: 80%;
display: flex ;
align-items: center;
color: ${(props) => props.isActive ? `${colors.lightBlue}` : ` ${colors.DarkBlue}`};
font-size: 15px;
margin-bottom: 25px;
background: transparent;
border: none;
margin-left: 30px;
cursor: pointer;

&:hover {
    color: ${colors.ligthGray} ;
    text-decoration: underline;
}


svg {
    width:30px;
  height: 25px;
  filter:  ${(props) => props.isActive ? `invert(0%) sepia(100%) saturate(2%) hue-rotate(39deg) brightness(105%) contrast(101%)` : ` brightness(0) saturate(100%) invert(12%) sepia(5%) saturate(5311%) hue-rotate(158deg) brightness(102%) contrast(93%)`};
 
};

div {
    width:40px;
  height: 40px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right:  14px;
  background-color: ${(props) => props.isActive ? `${colors.DarkBlue}` : ` transparent`}; 
}
`;