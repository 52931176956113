import React from "react";
import { Flex, Text } from "@chakra-ui/react";

const CardDescription = ({ description, infoCount }) => {
  return (
    <Flex width={"55%"} mt={"25px"} marginTop={"20px"}>
      <Flex
        bgColor={"#0071A1"}
        borderRadius={"5px"}
        padding={"20px"}
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"280px"}
      >
        <Text fontSize={"14px"} color={"#fff"}>
          {description}
        </Text>
        <Text fontSize={"14px"} color={"#fff"} fontWeight={"bold"}>
          {infoCount}
        </Text>
      </Flex>
    </Flex>
  );
};

export default CardDescription;
