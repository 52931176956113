import { Form } from "@unform/web";
import styled from "styled-components";
import colors from "../../../../styles/colors";
import fonts from "../../../../styles/fonts";


export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width:100vw;
    background-color: rgba(20,0,0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
`;

export const Body = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white};
    width: 85%;
    border-radius: 30px;
   
    
    h1 {
        ${fonts.notoSans700}
        color: ${colors.DarkBlue};
        margin-top: 1rem;
        width: 100%;
        text-align: center;
       
    }

    a {
        text-decoration: none;
        font-size: 20px;
        ${fonts.Mulish400};
        color: ${colors.DarkBlue};
        font-weight: normal;
        :hover {
            color: ${colors.lightBlue};

        }
        

  
    }

    overflow: auto;
    -ms-overflow-style: none;
    ::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 30px !important;
        margin-right: 70px !important;
    }

    ::-webkit-scrollbar {
        width: 8px;
        background: transparent;
        border-radius: 30px !important;
        margin-right: 30px;
    }
    ::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 30px !important;
        margin-right: 30px !important;

    }
`;

export const Forms = styled(Form) `
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 80%;
    height: 70%;
    justify-content: center;
    align-items: center;
    gap: 0px 30px;

    
    
    span {
        //margin-top: 20px;
        font-size: 20px;
        color: ${colors.DarkBlue};
        ${fonts.Mulish400};
        
    }
`
export const Content = styled.tr `
     > td {
         padding: 20px 0px;

     }

      >td:nth-child(1) {
          width: 150px;

      }

      >td:nth-child(3) {
          width: 400px;
      }

      >td:nth-child(5) {
          width: 400px;
      }
    button {
        background: transparent;
        border: none;
        margin-right: 5px;
        cursor: pointer;
        margin-left: 25px;
        
        
    }
    ;
`
export const ContainerTable = styled.table `
    align-self: center;
    background-color: #fff;
    margin-top: 0px;
    width: 100%;
    ${fonts.notoSans400}
    font-size: 14px;
    text-align: center;
    border-radius: 10px;
    tr {
        height: 50px;
        
    }


`;

export const SecundaryButton = styled.button`
    width: 148.5px;
    height: 51.3px;
    background-color: ${colors.white};
    color: ${colors.DarkBlue};
    ${fonts.notoSans700};
    font-size: 18px;
    border: 1px solid ${colors.DarkBlue};
    border-radius: 11px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    cursor: pointer;
    a {
        text-decoration: none;
        color: ${colors.DarkBlue};
       
    }
    :hover {
        background-color: ${colors.DarkBlue};
        color: ${colors.white};
        font-size: 20px;
        font-weight: bold;
      a {
        color:${colors.white}
      }  
    }
`
